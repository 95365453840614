import type { GridSortModel } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useQueryParams, NumberParam, StringParam, withDefault } from 'use-query-params';

import { convertStringToSortModel } from '../utils/convertStringToSortModel';
import { serializeSortModel } from '../utils/serializeSortModel';

export const useGroupsUrlSync = () => {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 100),
    name: StringParam,
    organizationId: StringParam,
    sort: StringParam,
  });

  const { page, size, name, organizationId, sort } = query;

  const sortModel: GridSortModel = useMemo(() => convertStringToSortModel(sort || ''), [sort]);

  const handlePageChange = (newPage: number) => setQuery({ page: newPage });
  const handleSizeChange = (newSize: number) => setQuery({ size: newSize });
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setQuery({ sort: serializeSortModel(newSortModel) });
  };
  const handleNameChange = (newSearchName: string) => setQuery({ name: newSearchName });
  const handleOrganizationChange = (newSearchOrganization: string) =>
    setQuery({ organizationId: newSearchOrganization });

  return {
    page,
    size: size,
    sortModel,
    name: name || '',
    organizationId: organizationId || '',
    handlePageChange,
    handleSizeChange,
    handleSortModelChange,
    handleNameChange,
    handleOrganizationChange,
  };
};
