import type { RpcRestError } from '@deepup/api-utils';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useErrorHandler = ({
  errors,
  defaultMessage,
  onError,
}: {
  onError: (message: string) => void;
  defaultMessage: string;
  errors: (RpcRestError | undefined)[];
}) => {
  useEffect(() => {
    const activeError = errors.find((error) => error !== null && error !== undefined);

    if (activeError) {
      const errorMessage = activeError.body?.message ?? defaultMessage;

      onError(errorMessage);
    }
  }, [defaultMessage, errors, onError]);
};

export const useSWRMutationErrorHandler = () => {
  const { t } = useTranslation();

  return (error: RpcRestError) => {
    enqueueSnackbar(error.body?.message ?? t('common.failedToLoad'), {
      variant: 'error',
    });
  };
};
