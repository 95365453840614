import { PlusCircleOutline } from '@deepup/icons';
import { Box, Button } from '@mui/material';
import { DataGridPro, type GridColDef } from '@mui/x-data-grid-pro';
import { useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { renderCellContent } from '@components/TableCell/TableCell';
import type { Project } from '@hooks/api/projects';

interface AllProjectsForApiAccessTableProps {
  projects?: Project[];
  searchString?: string;
  onAddProject: (projectsToAdd: Project[]) => void;
  selectedProjects: Project[];
  isLoading: boolean;
}

export const AllProjectsForApiAccessTable: FC<AllProjectsForApiAccessTableProps> = ({
  projects,
  searchString,
  onAddProject,
  selectedProjects,
  isLoading,
}) => {
  const { t } = useTranslation();

  const filteredProjects = useMemo(() => {
    return selectedProjects.length === 0 && !searchString
      ? projects
      : projects?.filter((project) => {
          let include = true;

          if (searchString) {
            const projectNameLowerCase = project.name.toLowerCase();

            const searchStringLowerCase = searchString.toLowerCase();

            include = projectNameLowerCase.indexOf(searchStringLowerCase) > -1;
          }

          if (include && selectedProjects.length > 0)
            include = !selectedProjects.find(({ id }) => id === project.id);

          return include;
        });
  }, [selectedProjects, searchString, projects]);

  const handleAddAllProjects = () => {
    filteredProjects && onAddProject(filteredProjects);
  };

  const handleAddProject = (project: Project) => () => {
    onAddProject([project]);
  };

  if (!filteredProjects) return null;

  const columns: GridColDef[] = [
    {
      flex: 1,
      field: 'name',
      headerName: 'Name',
      renderCell: (params) =>
        renderCellContent({
          entityType: 'all-api-projects',
          params,
          isLoading,
          columnField: 'name',
        }),
    },
    {
      flex: 1,
      field: 'context-menu',
      headerName: '',
      sortable: false,
      renderHeader: () => (
        <Box display="flex" justifyContent="flex-end" width="100vw">
          <Button
            color="secondary"
            disabled={filteredProjects.length === 0}
            onClick={handleAddAllProjects}
            startIcon={<PlusCircleOutline />}
            variant="text"
          >
            {t('pages.apiAccesses.form.projects.components.btnAddAllProjects')}
          </Button>
        </Box>
      ),
      renderCell: (params) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button
            color="secondary"
            data-testid={`add-project-${params.row.name}`}
            onClick={handleAddProject(params.row)}
            startIcon={<PlusCircleOutline />}
            variant="text"
          >
            {t('pages.apiAccesses.form.projects.components.btnAddOneProject')}
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <DataGridPro<Project>
      columns={columns}
      disableColumnMenu
      disableRowSelectionOnClick
      loading={isLoading}
      pagination
      rows={filteredProjects}
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
    />
  );
};
