import type { User, UserWithOrganization } from '@hooks/api/users';
import type { UserOrgRelation } from '@models/users';
import { deepUpOrganizationIds } from '@src/constants';

export function isUserInDeepUpOrg(user?: User | UserWithOrganization) {
  if (!user) {
    return false;
  }

  if ('organization' in user) return user.organization?.isDeepUpOrg;
  if (user.organizationId) return deepUpOrganizationIds.includes(user.organizationId);

  return false;
}

export function getOpenOrgInvitations(userOrgRelations: UserOrgRelation[]) {
  return userOrgRelations.filter(({ hasConsented }) => !hasConsented);
}

export function getOpenOrgInvitationCount(userOrgRelations: UserOrgRelation[]) {
  return getOpenOrgInvitations(userOrgRelations).length;
}

export const hasOpenOrgInvitations = (userOrgRelations: UserOrgRelation[]) => {
  if (!userOrgRelations || !Array.isArray(userOrgRelations)) {
    return false;
  }

  return !!getOpenOrgInvitationCount(userOrgRelations);
};
