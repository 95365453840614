import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { usePlatformAdminCreateApi, usePlatformAdminDeleteApi } from '@hooks/api/platform';
import { useHandleFetchError } from '@hooks/api/useHandleFetchError';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { OverviewPageLayout } from '@pages/common';

import {
  PlatformAdministratorForm,
  type PlatformAdministratorFormValues,
} from './PlatformAdministratorForm';

export const PlatformAdministratorEdit: FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();

  const createPlatformAdmin = usePlatformAdminCreateApi();
  const deletePlatformAdmin = usePlatformAdminDeleteApi();
  const handleFetchError = useHandleFetchError();

  const handleAdd = async (data: PlatformAdministratorFormValues) => {
    try {
      await createPlatformAdmin(data);

      showMessage({
        message: t('pages.platform.platformAdmin.messages.createSuccess'),
        type: 'success',
      });
    } catch (error) {
      handleFetchError(error);
    }
  };

  const handleRemove = async (data: PlatformAdministratorFormValues) => {
    try {
      await deletePlatformAdmin(data);

      showMessage({
        message: t('pages.platform.platformAdmin.messages.removeSuccess'),
        type: 'success',
      });
    } catch (error) {
      handleFetchError(error);
    }
  };

  return (
    <OverviewPageLayout title={t('pages.platform.overview.title')}>
      <PlatformAdministratorForm onAdd={handleAdd} onRemove={handleRemove} />
    </OverviewPageLayout>
  );
};
