import {
  DataGridPro,
  type GridColDef,
  type GridFilterModel,
  type GridPaginationModel,
  type GridRowParams,
  type GridSortModel,
} from '@mui/x-data-grid-pro';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { renderCellContent } from '@components/TableCell/TableCell';
import type { Organization } from '@models/organizations';
import { getLocaleTextForDataGrid } from '@utils/getLocaleTextForDataGrid';

interface OrganizationTableProps {
  organizations: Organization[];
  isLoading: boolean;
  page: number;
  size: number;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
  sortModel: GridSortModel;
  rowCount: number;
  name?: string;
}

export const OrganizationsTable: FC<OrganizationTableProps> = ({
  organizations,
  isLoading,
  page,
  size,
  sortModel,
  rowCount,
  onSizeChange,
  onPageChange,
  onSortModelChange,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const pageSizeOptions = [25, 50, 100];

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: page,
    pageSize: size,
  });

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel);
    onPageChange(newModel.page);
    onSizeChange(newModel.pageSize);
    onSizeChange(newModel.pageSize);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('entities.organization.fields.name.label'),
      flex: 0.5,
      renderHeader: (params) => (
        <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'organizations',
          params,
          isLoading,
          columnField: 'name',
        }),
    },

    {
      field: 'abbreviation',
      headerName: t('entities.organization.fields.abbreviation.label'),
      flex: 0.2,
      renderHeader: (params) => (
        <span data-testid="table-header-cell-Abbreviation">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'organizations',
          params,
          isLoading,
          columnField: 'abbreviation',
        }),
    },

    {
      field: 'projects',
      headerName: t('pages.projects.overview.title'),
      flex: 0.15,
      valueGetter: (_, row) => row.projectsCount,
      sortable: false,
      renderHeader: (params) => (
        <span data-testid="table-header-cell-Projects">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'organizations',
          params,
          isLoading,
          columnField: 'projects',
        }),
    },

    {
      field: 'user',
      headerName: t('entities.user.label'),
      flex: 0.15,
      valueGetter: (_, row) => row.usersCount,
      sortable: false,
      renderHeader: (params) => (
        <span data-testid="table-header-cell-User">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'organizations',
          params,
          isLoading,
          columnField: 'user',
        }),
    },
  ];

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/organizations/${params.id}/overview`, {
      state: {
        organization: organizations.find((organization) => organization.id === params.id),
      },
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <DataGridPro
        columns={columns}
        data-testid="table-organizations"
        disableColumnMenu
        filterMode="server"
        filterModel={filterModel}
        loading={isLoading}
        localeText={getLocaleTextForDataGrid(i18n.language)}
        onFilterModelChange={setFilterModel}
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={handleRowClick}
        onSortModelChange={onSortModelChange}
        pageSizeOptions={pageSizeOptions}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        rows={organizations}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        sortModel={sortModel}
        sortingMode="server"
        sortingOrder={['asc', 'desc']}
      />
    </div>
  );
};
