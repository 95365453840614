import type { PlatformUserType } from '@hooks/usePlatformPermissions';

import type { RecursivePartial, RenderConfig } from './types';

const PLATFORM_ADMIN = {};

const ORGANIZATION_ADMIN = {
  sidebar: {
    platform: false,
    deepupGroups: false,
  },
  organizations: {
    overview: {
      components: {
        btnCreate: 'hidden',
      },
    },
    edit: {
      form: {
        mode: 'disabled',
        fields: {
          dashboardIframeSourceUrl: 'hidden',
          invoiceToolEnabled: 'hidden',
        },
      },
    },
  },
  projects: {
    overview: {
      components: {
        btnCreate: 'hidden',
        clientIcon: 'hidden',
      },
    },
    edit: {
      components: {
        tabApiAccesses: 'hidden',
      },
      form: {
        mode: 'disabled',
        fields: {
          networkProvider: 'hidden',
          generalContractor: 'hidden',
        },
      },
    },
  },
  user: {
    create: {
      components: {
        advancedSettings: 'hidden',
        deepupUserCreate: 'hidden',
        organizationSelect: 'disabled',
      },
    },
    components: {
      contextMenu: {
        elements: {
          auth0: 'hidden',
        },
      },
    },
  },
  groups: {
    overview: {
      form: {
        fields: {
          organization: 'disabled',
        },
      },
    },
  },
  apiAccesses: {
    overview: {
      components: {
        table: {
          columns: {
            organization: 'hidden',
          },
        },
      },
    },
  },
};

const ORGANIZATION_MEMBER = {
  ...ORGANIZATION_ADMIN,
  sidebar: {
    ...ORGANIZATION_ADMIN.sidebar,
    apiAccesses: false,
  },
  user: {
    ...ORGANIZATION_ADMIN.user,
    overview: {
      components: {
        btnCreate: 'hidden',
      },
    },
    create: {
      components: {
        ...ORGANIZATION_ADMIN.user.create.components,
        externalUserCreate: 'hidden',
      },
    },
    edit: {
      form: {
        mode: 'disabled',
      },
    },
    components: {
      ...ORGANIZATION_ADMIN.user.components,
      contextMenu: {
        ...ORGANIZATION_ADMIN.user.components.contextMenu,
        mode: 'hidden',
      },
    },
  },
  groups: {
    overview: {
      components: {
        btnCreate: 'hidden',
      },
    },
    create: {
      form: {
        mode: 'disabled',
      },
    },
    edit: {
      form: {
        mode: 'disabled',
      },
    },
  },
};

const EXTERNAL = {
  ...ORGANIZATION_MEMBER,
  sidebar: {
    ...ORGANIZATION_MEMBER.sidebar,
    organizations: false,
    user: false,
  },
};

export const renderConfig: Partial<{ [key in PlatformUserType]: RecursivePartial<RenderConfig> }> =
  {
    PLATFORM_ADMIN,
    ORGANIZATION_ADMIN,
    ORGANIZATION_MEMBER,
    EXTERNAL,
  };
