import { object, string } from 'yup';

import { validateDeepupEmail } from '@utils/formValidation';

export type PlatformAdministratorFormValues = {
  emailAddress?: string;
};

export const platformAdministratorFormSchema = object({
  emailAddress: string().test('is-valid-deepup-email', '', validateDeepupEmail(false)),
});
