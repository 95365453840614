import { Autocomplete, Box, FormControl, TextField, useMediaQuery, useTheme } from '@mui/material';
import { useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { UserType } from '@models/users';

interface UserTypeSelectProps {
  selectedUserType?: UserType;
  label: string;
  onChange: (newValue: UserType) => void;
  defaultLabel: string;
  canCreateExternalUser?: boolean;
  canCreateDeepupUser?: boolean;
}

export const UserTypeSelect: FC<UserTypeSelectProps> = ({
  label,
  onChange,
  defaultLabel,
  canCreateExternalUser,
  canCreateDeepupUser,
}) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const options = [
      {
        id: 'internal',
        name: `${t('pages.user.form.custom.internalUser')} (${t(
          'pages.user.form.info.userType.internal',
        )})`,
      },
    ];

    if (canCreateExternalUser) {
      options.push({
        id: 'external',
        name: `${t('pages.user.form.custom.externalUser')} (${t(
          'pages.user.form.info.userType.external',
        )})`,
      });
    }

    if (canCreateDeepupUser) {
      options.push({
        id: 'deepup',
        name: `${t('pages.user.form.custom.deepupUser')} (${t(
          'pages.user.form.info.userType.deepup',
        )})`,
      });
    }

    return options;
  }, [canCreateExternalUser, canCreateDeepupUser, t]);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const inputWidth = isDesktop ? '50%' : '100%';

  return (
    <Box display="flex" flexDirection="column" mb={3}>
      <FormControl sx={{ display: 'flex', flexDirection: 'row', width: inputWidth }}>
        <Box flex="1">
          <Autocomplete
            getOptionLabel={(p) => p.name}
            id="combo-box-user-types"
            onChange={(_, value) => {
              if (value?.id) onChange(value.id as UserType);
            }}
            options={options}
            renderInput={(params) => (
              <TextField {...params} label={label} placeholder={defaultLabel} />
            )}
          />
        </Box>
      </FormControl>
    </Box>
  );
};
