import type { GridSortModel } from '@mui/x-data-grid-pro';

export const convertSortModelToString = (sortModel?: GridSortModel): string | null => {
  if (sortModel?.[0]?.field && sortModel?.[0]?.sort) {
    const sortFieldMappings: { [key: string]: string } = {
      organization: 'organization_name',
      organizationName: 'organization_name',
      generalContractor: 'generalContractor_name',
      networkProvider: 'networkProvider_name',
      startDate: 'period_startDate',
    };

    const sortField = sortFieldMappings[sortModel[0].field] || sortModel[0].field;

    return `${sortField},${sortModel[0].sort}`;
  }

  return null;
};
