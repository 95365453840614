import { Hourglass } from '@deepup/icons';
import { Typography, Tooltip } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import type { GridColDef } from '@mui/x-data-grid-pro';
import type { TFunction } from 'i18next';
import type { NavigateFunction } from 'react-router-dom';

import { renderCellContent } from '@components/TableCell/TableCell';
import { UserContextMenu } from '@components/UserContextMenu';
import type { UserWithOrganization } from '@hooks/api/users';
import { hasOpenOrgInvitations } from '@utils/user';

export const getUserTableColumns = (
  t: TFunction,
  isLoading: boolean,
  navigate: NavigateFunction,
  theme: Theme,
  isItemVisible: (key: string) => boolean,
  getCellStyle: (user: UserWithOrganization) => Record<string, unknown>,
  setDeleteUserId: (userId: string | null) => void,
  setConfirmDialogOpen: (isOpen: boolean) => void,
): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: t('entities.user.fields.name.label'),
      flex: 1,
      renderCell: (params) =>
        renderCellContent({
          entityType: 'user',
          params,
          isLoading,
          columnField: 'name',
          renderContent: (value) => (
            <>
              {hasOpenOrgInvitations(params.row.organizationRelations) && (
                <Tooltip title={t('common.waitingForRegistration')}>
                  <Hourglass fill={theme.palette.text.disabled} height={24} width={24} />
                </Tooltip>
              )}
              <Typography
                sx={{
                  marginLeft: hasOpenOrgInvitations(params.row.organizationRelations) ? 1 : 0,
                  ...getCellStyle(params.row),
                }}
                variant="body2"
              >
                {value}
              </Typography>
            </>
          ),
        }),
    },
    {
      field: 'email',
      headerName: t('entities.user.fields.email.label'),
      flex: 1,
      renderCell: (params) =>
        renderCellContent({
          entityType: 'user',
          params,
          isLoading,
          columnField: 'email',
          renderContent: (value) => (
            <Typography sx={getCellStyle(params.row)} variant="body2">
              {value}
            </Typography>
          ),
        }),
    },
    {
      field: 'organizationName',
      headerName: t('entities.organization.label'),
      flex: 1,
      renderCell: (params) =>
        renderCellContent({
          entityType: 'user',
          params,
          isLoading,
          columnField: 'organization',
          renderContent: () => (
            <Typography sx={getCellStyle(params.row)} variant="body2">
              {params.row.organizationName || ''}
            </Typography>
          ),
        }),
    },
    {
      field: 'context-menu',
      headerName: '',
      width: 50,
      align: 'right',
      sortable: false,
      renderCell: (params) =>
        renderCellContent({
          entityType: 'user',
          params,
          isLoading,
          columnField: 'context-menu',
          renderContent: () => {
            const user = params.row;
            const handleEdit = () => navigate(`/users/${user.id}/edit`);
            const handleDelete = () => {
              setDeleteUserId(user.id);
              setConfirmDialogOpen(true);
            };

            return (
              isItemVisible('user.components.contextMenu.mode') && (
                <UserContextMenu
                  hasEdit
                  hasResendInvitation={hasOpenOrgInvitations(user.organizationRelations)}
                  onClickDelete={handleDelete}
                  onClickEdit={handleEdit}
                  user={user}
                />
              )
            );
          },
        }),
    },
  ];
};
