import {
  Autocomplete,
  TextField,
  type SelectProps,
  Typography,
  useTheme,
  useMediaQuery,
  Stack,
} from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import type { ChangeEvent, ReactNode } from 'react';
import {
  type Control,
  Controller,
  type DeepRequired,
  type FieldError,
  type FieldErrorsImpl,
  type FieldValues,
  type Merge,
  type Path,
  type PathValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { Organization } from '@models/organizations';

type OrganizationSelectProps<T extends FieldValues> = Omit<SelectProps, 'error' | 'onChange'> & {
  label: ReactNode;
  items: Organization[];
  defaultValue?: string | null;
  defaultItemLabel?: string | null;
  formFieldName?: string;
  error?: Merge<FieldError, FieldErrorsImpl<DeepRequired<unknown>>> | undefined;
  control: Control<T, object>;
  disabled?: boolean;
  onChange?: (value: string) => void;
  isRequired?: boolean;
  dataTestId?: string;
  helperText?: string;
};

export const getSelectValue = (selectValue: string | undefined | null) => {
  if (selectValue === 'external') {
    return undefined;
  }

  return selectValue;
};

export const OrganizationSelect = <T extends FieldValues>({
  label,
  items,
  defaultValue,
  formFieldName = 'organizationId',
  error,
  control,
  disabled,
  onChange: onChangeProp,
  dataTestId,
  helperText,
}: OrganizationSelectProps<T>) => {
  const { t } = useTranslation();

  const organizationForDefaultValueId = items.find(
    (item: Organization) => item.id === defaultValue,
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const inputWidth = isDesktop ? '50%' : '100%';

  return (
    <Box display="flex" flexDirection="column" mb={2}>
      <FormControl
        disabled={!!disabled}
        sx={{ display: 'flex', flexDirection: 'row', width: inputWidth }}
      >
        <Box display="flex" flex="1 0 0" flexDirection="column">
          <Controller
            control={control}
            defaultValue={defaultValue as PathValue<T, Path<T>> | undefined}
            name={formFieldName as Path<T>}
            render={({ field: { onChange } }) => (
              <Autocomplete
                data-testid={dataTestId}
                defaultValue={organizationForDefaultValueId}
                disabled={disabled}
                getOptionLabel={(p) => p.name}
                id={`combo-box-organizations-${label}`}
                onChange={(_, value) => {
                  onChange(value?.id as PathValue<T, Path<T>> | ChangeEvent<Element>);
                  if (onChangeProp) onChangeProp(String(value?.id ?? ''));
                }}
                options={items}
                renderInput={(params) => (
                  <TextField
                    label={label}
                    {...params}
                    FormHelperTextProps={{ id: 'organization-select-error' }}
                    error={!!error}
                    helperText={
                      <Stack component="span">
                        <Typography component="span" variant="body2">
                          {error?.message?.toString()}
                        </Typography>
                        <Typography component="span" variant="body2">
                          {helperText}
                        </Typography>
                      </Stack>
                    }
                    placeholder={t('common.pleaseChoose')}
                  />
                )}
              />
            )}
          />
        </Box>
      </FormControl>
    </Box>
  );
};
