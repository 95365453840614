import {
  Autocomplete,
  FormControl,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { Controller, type Control, type DeepRequired, type FieldErrorsImpl } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import type { Organization } from '@models/organizations';

import { countryCodes } from '../countryCodes';
import type { OrganizationFormSchema } from '../schema';

interface CountryCodeFormTypeProps {
  formErrors: FieldErrorsImpl<DeepRequired<Organization>>;
  organization?: Organization;
  control: Control<OrganizationFormSchema>;
}

export const CountryCodeFormType = ({
  organization,
  formErrors,
  control,
}: CountryCodeFormTypeProps) => {
  const { t } = useTranslation();
  const { isItemEnabled } = useRenderConfig();

  const isFormEnabled = isItemEnabled('organizations.edit.form.mode');

  const currentCountryCodeType = countryCodes.find(
    (countryCode) => countryCode.id === organization?.billingAddress?.countryCode,
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const inputWidth = isDesktop ? '50%' : '100%';

  return (
    <Stack mb={2} width={inputWidth}>
      <FormControl disabled={!isFormEnabled}>
        <Controller
          control={control}
          defaultValue={currentCountryCodeType?.id}
          name={'billingAddress.countryCode'}
          render={({ field: { onChange } }) => (
            <Autocomplete
              defaultValue={currentCountryCodeType}
              disabled={!isFormEnabled}
              fullWidth
              getOptionLabel={(p) => p.name}
              onChange={(_, value) => {
                onChange(value?.id);
              }}
              options={countryCodes}
              renderInput={(params) => (
                <TextField
                  label={t('entities.organization.fields.billing.country.label')}
                  {...params}
                  error={!!formErrors?.billingAddress?.countryCode}
                  helperText={
                    <Box component="span">
                      <Typography component="span" variant="body2">
                        {formErrors?.billingAddress?.countryCode?.message?.toString()}
                      </Typography>
                    </Box>
                  }
                  placeholder={t('common.pleaseChoose')}
                />
              )}
            />
          )}
        />
      </FormControl>
    </Stack>
  );
};
