import { enUS, deDE } from '@mui/x-data-grid-pro/locales';

export const getLocaleTextForDataGrid = (language: string) => {
  switch (language) {
    case 'de':
    case 'de-DE':
      return deDE.components.MuiDataGrid.defaultProps.localeText;
    case 'en':
    case 'en-US':
    default:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
  }
};
