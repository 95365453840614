import type { GridSortModel } from '@mui/x-data-grid-pro';

import { useApiWithOptions } from '../useApiWithOptions';
import { convertSortModelToString } from '../utils/convertSortModelToString';

import type { ApiAccess } from './types';

interface ApiAccessesQueryOptions {
  page: number;
  size: number;
  sortModel?: GridSortModel;
  name?: string;
}

const constructQueryStringForApiAccesses = ({
  page,
  size,
  sortModel,
  name,
}: ApiAccessesQueryOptions): string => {
  const sortParam = convertSortModelToString(sortModel);
  const queryParams = {
    page: page.toString(),
    size: size.toString(),
    ...(name && { name }),
    ...(sortParam && { sort: sortParam }),
  };
  const searchParams = new URLSearchParams(queryParams);

  return `?${searchParams.toString()}`;
};

export function useApiAccessesPaginatedApi(queryOptions: ApiAccessesQueryOptions) {
  return useApiWithOptions<ApiAccess>({
    context: 'api-access',
    queryString: constructQueryStringForApiAccesses(queryOptions),
  });
}
