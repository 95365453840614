import { Box } from '@mui/material';
import {
  DataGridPro,
  type GridColDef,
  type GridFilterModel,
  type GridPaginationModel,
  type GridSortModel,
} from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { useMemo, useState, type FC } from 'react';

import { DeleteDialog } from '@components/DeleteDialog';
import { renderCellContent } from '@components/TableCell/TableCell';
import type { ApiAccessListItem } from '@hooks/api/apiAccesses';
import { useApiAccessesDeleteApi } from '@hooks/api/apiAccesses/useApiAccessesDeleteApi';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useRenderConfig } from '@hooks/ui';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import { ApiAccessContextMenu } from '../ApiAccessContextMenu';

interface ApiAccessesOverviewTableProps {
  entities: ApiAccessListItem[];
  name: string;
  onEdit: (apiAccessId: string) => void;
  isLoading: boolean;
  page: number;
  size: number;
  onPageChange: (page: number) => void;
  onSizeChange: (pageSize: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
  sortModel: GridSortModel;
  rowCount: number;
}

export const ApiAccessesOverviewTable: FC<ApiAccessesOverviewTableProps> = ({
  entities,
  name,
  onEdit,
  isLoading,
  page,
  size,
  sortModel,
  rowCount,
  onSizeChange,
  onPageChange,
  onSortModelChange,
}) => {
  const { showMessage } = useSnackbarMessage();
  const { isItemVisible } = useRenderConfig();
  const isColumnOrganizationVisible = isItemVisible(
    'apiAccesses.overview.components.table.columns.organization',
  );
  const deleteApiAccess = useApiAccessesDeleteApi();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [deletingActive, setDeletingActive] = useState<boolean>(false);
  const [deleteApiAccessId, setDeleteApiAccessId] = useState<string | null>(null);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: page,
    pageSize: size,
  });

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel);
    onPageChange(newModel.page);
    onSizeChange(newModel.pageSize);
  };

  const handleClickDeleteApiAccess = (id: string) => () => {
    setConfirmDialogOpen(true);
    setDeleteApiAccessId(id);
  };

  const enableUserInteractionAfterDeletion = () => {
    setDeletingActive(false);
    setConfirmDialogOpen(false);
  };

  const handleConfirmedDelete = async (confirmed: boolean) => {
    if (confirmed && deleteApiAccessId) {
      setDeletingActive(true);
      try {
        await deleteApiAccess(deleteApiAccessId);
        setDeleteApiAccessId(null);
        revalidateCacheForEntity('/api-access');

        showMessage({
          message: t('pages.apiAccesses.edit.messages.deleteSuccess'),
          type: 'success',
        });
      } catch (error) {
        console.error(error);
        showMessage({ message: (error as Error).toString(), type: 'error' });
      } finally {
        enableUserInteractionAfterDeletion();
      }
    } else {
      setConfirmDialogOpen(false);
    }
  };

  const filteredApiAccesses = useMemo(() => {
    const searchText = name.toLowerCase();

    if (name.length < 2) return entities;

    return entities.filter((item) => {
      const itemName = item.clientId.toLowerCase();

      return itemName.indexOf(searchText) > -1;
    });
  }, [name, entities]);

  const columns: GridColDef[] = [
    {
      field: 'clientId',
      headerName: t('pages.apiAccesses.overview.components.table.clientId'),
      flex: 1,
      renderHeader: (params) => (
        <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'api-access',
          params,
          isLoading,
          columnField: 'clientId',
          key: 'clientId',
        }),
    },
    {
      field: 'organizationName',
      headerName: t('entities.organization.label'),
      flex: 1,
      renderHeader: (params) => (
        <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'apiAccess',
          params,
          isLoading,
          columnField: 'organization',
        }),
    },
    {
      field: 'context-menu',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: ({ id }) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <ApiAccessContextMenu
            apiAccessId={String(id)}
            hasEdit
            onClickDelete={handleClickDeleteApiAccess(String(id))}
            onClickEdit={() => onEdit(String(id))}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <DataGridPro<ApiAccessListItem>
        columns={columns}
        data-testid="table-api-access-overview"
        disableColumnMenu
        filterMode="server"
        filterModel={filterModel}
        getRowId={(row) => row.clientId}
        initialState={{
          columns: {
            columnVisibilityModel: {
              organization: !isColumnOrganizationVisible,
            },
          },
        }}
        loading={isLoading}
        onFilterModelChange={setFilterModel}
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={(params) => onEdit(params.row.clientId)}
        onSortModelChange={onSortModelChange}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        rowSelection={false}
        rows={filteredApiAccesses}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        sortModel={sortModel}
        sortingMode="server"
        sortingOrder={['asc', 'desc']}
      />
      <DeleteDialog
        isDeletingActive={deletingActive}
        message={t('pages.apiAccesses.overview.components.dialog.content')}
        onClick={handleConfirmedDelete}
        open={confirmDialogOpen}
        title={t('pages.apiAccesses.overview.components.dialog.title')}
      />
    </>
  );
};
