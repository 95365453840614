import type { User, UserWithOrganization } from '@hooks/api/users';
import type { Organization } from '@models/organizations';

export function getUsersWithOrganization(
  users: User[],
  organizations: Organization[],
): UserWithOrganization[] {
  return users.map((user) => {
    const organizationOfUser = organizations.find(
      (organization) => organization.id === user.organizationId,
    );

    return {
      ...user,
      organization: organizationOfUser,
    };
  });
}

export function filterUsersByOrganizationId(
  usersWithOrganization: UserWithOrganization[],
  organizationId?: string,
) {
  return usersWithOrganization.filter((user) => user.organization?.id === organizationId);
}

export function filterUsersByDeepUpOrganizations(
  usersWithOrganization: UserWithOrganization[],
  includeOrExclude: 'include' | 'exclude',
) {
  return usersWithOrganization.filter((user) => {
    if (includeOrExclude === 'include') return !!user.organization?.isDeepUpOrg;
    if (includeOrExclude === 'exclude') return !user.organization?.isDeepUpOrg;
  });
}
