import { RpcRestError } from '@deepup/api-utils';
import {
  commonApiV1,
  billingApiV1 as billingApi,
  billingClientApiV1 as billingClientApi,
} from '@deepup/apis';
import useSWR from 'swr';

import { useGrpcRestTransport } from '@hooks/api/useGrpcTransport';

import { useSWRMutationErrorHandler } from './useErrorHandler';

export const useApiGetScannerRent = ({
  organizationId,
  listOptions,
  dateRange,
  isEnabled,
}: {
  organizationId: string | null;
  dateRange: {
    start: string;
    end: string;
  };
  listOptions: {
    pageSize: number;
    page: number;
  };
  isEnabled: boolean;
}) => {
  const transport = useGrpcRestTransport();
  const handleError = useSWRMutationErrorHandler();

  const key = isEnabled
    ? [
        'billing',
        'scannerRent',
        organizationId,
        listOptions.page,
        listOptions.pageSize,
        dateRange.start,
        dateRange.end,
      ].join('-')
    : null;

  const timeRange = commonApiV1.TimeRange.fromJson({
    start: dateRange.start,
    end: dateRange.end,
  });

  return useSWR<billingApi.GetScannerRentResponse, RpcRestError>(
    key,
    () => {
      const client = new billingClientApi.BillingServiceClient(transport);

      return client.getScannerRent({
        organizationId: organizationId ?? '',
        timeRange,
        options: {
          pageSize: listOptions.pageSize,
          page: BigInt(listOptions.page),
          contextId: '',
          withPaginationInfo: true,
        },
      }).response;
    },
    {
      shouldRetryOnError: false,
      onError: handleError,
    },
  );
};
