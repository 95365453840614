import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { type FC } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput } from '@components/FormElements';
// import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import { platformAdministratorFormSchema, type PlatformAdministratorFormValues } from './schema';

interface PlatformAdministratorFormProps {
  onAdd: (data: PlatformAdministratorFormValues) => void;
  onRemove: (data: PlatformAdministratorFormValues) => void;
}

export const PlatformAdministratorForm: FC<PlatformAdministratorFormProps> = ({
  onAdd,
  onRemove,
}) => {
  const { t } = useTranslation();

  const { register, handleSubmit, control } = useForm({
    resolver: yupResolver(platformAdministratorFormSchema),
  });
  const { errors: formErrors } = useFormState({ control });

  return (
    <form>
      <Stack gap={2}>
        <Typography variant="h6">{t('pages.platform.platformAdmin.title')}</Typography>
        <Alert severity="warning" variant="outlined">
          {t('pages.platform.platformAdmin.messages.warning')}
        </Alert>
        <Stack direction="row" gap={2} marginTop={2}>
          <FormInput
            error={formErrors.emailAddress}
            formFieldName="emailAddress"
            fullWidth
            helperText={t('pages.platform.platformAdmin.components.emailInput.helperText')}
            isRequired
            label={t('pages.platform.platformAdmin.components.emailInput.label')}
            placeholder={t('pages.platform.platformAdmin.components.emailInput.placeholder')}
            register={register}
          />
          <Button color="error" onClick={handleSubmit(onRemove)}>
            {t('pages.platform.platformAdmin.components.btnRemove')}
          </Button>
          <Button color="primary" onClick={handleSubmit(onAdd)}>
            {t('pages.platform.platformAdmin.components.btnAdd')}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
