import { boolean, number, object, string, array } from 'yup';

import {
  isOneInputEmpty,
  validateDate,
  validateDeepupEmail,
  validateMultiPolygonGeoJson,
} from '@utils/formValidation';

export const projectsFormSchema = ({
  error,
}: {
  error: {
    name: string;
    onlySpaces: string;
    organizationId: string;
    utmZone: string;
    multipolygonGeoJson: string;
    period: {
      startDate: string;
      endDate: string;
    };
    activityState: string;
    networkDesign: {
      state: string;
      format: string;
    };
    customerContactPerson: {
      userId: string;
    };
    contactPerson: {
      email: string;
    };
    customerManager: {
      email: string;
    };
    oneInputEmpty: string;
  };
}) =>
  object({
    id: string(),
    networkProviderId: string().nullable(),
    generalContractorId: string().nullable(),
    version: number(),
    name: string().required(error.name).matches(/\S/, error.onlySpaces),
    organizationId: string().notOneOf(['-1'], error.organizationId).required(error.organizationId),
    utmZone: number().min(0, error.utmZone).required(error.utmZone).typeError(error.utmZone),
    multipolygonGeoJson: string().test(
      'is-valid-geojson',
      error.multipolygonGeoJson,
      validateMultiPolygonGeoJson(true),
    ),
    period: object({
      startDate: string().test('is-valid-date', error.period.startDate, validateDate(true)),
      endDate: string().test('is-valid-date', error.period.endDate, validateDate(true)),
    }),
    networkDesign: object({
      state: string(),
      format: string(),
    }),
    activityState: string(),
    pipeDirection: boolean(),
    apiExport: boolean(),
    customerContactPerson: object({
      userId: string(),
    }),
    contactPerson: object({
      name: string(),
      email: string().test(
        'is-valid-deepup-email',
        error.contactPerson.email,
        validateDeepupEmail(true),
      ),
    }).test('name-email-check', error.oneInputEmpty, isOneInputEmpty),
    customerManager: object({
      name: string(),
      email: string().email(error.customerManager.email),
    }).test('name-email-check', error.oneInputEmpty, isOneInputEmpty),
    serviceProviders: array().of(
      object({
        groupId: string().required(),
        name: string().required(),
      }),
    ),
    customerOrderNumber: string(),
  });
