import { type billingApiV1 } from '@deepup/apis';
import { Box, Typography } from '@mui/material';
import {
  DataGridPro,
  type DataGridProProps,
  type GridColDef,
  type GridFeatureMode,
  type GridPaginationModel,
} from '@mui/x-data-grid-pro';
import { useFlags } from 'flagsmith/react';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { defaultPaginationModel } from '../OrganizationsEditDashboard';

import { tableStyles, TableToolbar } from './OrganizationsEditDashboardMeterTable';
import { useRentalRows } from './useRentalRows';

export const ScannerTable = ({
  progress,
  paginationModel,
  onPaginationModelChange,
  totalItems,
  isLoading,
  paginationMode,
  onStateChange,
  title,
  onDowload,
  isDownloading,
}: {
  progress: billingApiV1.GetScannerRentResponse['items'];
  paginationModel?: GridPaginationModel | undefined;
  onPaginationModelChange?: (model: GridPaginationModel) => void;
  totalItems?: bigint;
  isLoading?: boolean;
  paginationMode: GridFeatureMode;
  onStateChange?: DataGridProProps['onStateChange'];
  title: string;
  onDowload?: () => void;
  isDownloading?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const { id: orgId } = useParams();
  const { billing_prices_enabled } = useFlags(['billing_prices_enabled']);

  // NOTE: This is a workaround to prevent the table from showing prices for certain organizations.
  // Since the product want to define this list on the fly, we have to use a feature flag.
  const excludedOrgIds =
    (billing_prices_enabled.value &&
      JSON.parse(billing_prices_enabled.value as string)?.excludedOrgIds) ??
    [];

  const hidePrices = billing_prices_enabled.enabled && excludedOrgIds.includes(orgId);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCount` from being undefined during the loading
  const rowCountRef = useRef(totalItems || BigInt(0));

  const rowCount = useMemo(() => {
    if (totalItems !== undefined) {
      rowCountRef.current = totalItems;
    }

    return Number(rowCountRef.current);
  }, [totalItems]);

  const rows = useRentalRows(progress, i18n.language);

  const columns: GridColDef[] = [
    {
      field: 'scannerName',
      headerName: t('pages.organization.billing.scannerTable.name'),
      flex: 1,
    },
    {
      field: 'rentDuration',
      headerName: t('pages.organization.billing.scannerTable.rentDuration'),
      flex: 1,
    },
    {
      field: 'range',
      headerName: t('pages.organization.billing.scannerTable.range'),
      flex: 2,
    },
    {
      field: 'price',
      headerName: t('pages.organization.billing.scannerTable.price'),
      cellClassName: 'cell-price-total',
      headerClassName: 'cell-price-total',
      valueFormatter: (val) => `${t('decimal', { val })}€`,
      flex: 1,
    },
  ];

  return (
    <Box sx={{ width: '100%', minHeight: 300 }}>
      <DataGridPro
        columnVisibilityModel={{
          price: !hidePrices,
        }}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: defaultPaginationModel,
            rowCount,
          },
        }}
        loading={isLoading}
        onPaginationModelChange={onPaginationModelChange}
        onStateChange={onStateChange}
        pageSizeOptions={rowCount && rowCount > 5 ? [5, 10, 20, 50, 100] : [5]}
        pagination
        paginationMode={paginationMode}
        paginationModel={paginationModel}
        rowCount={paginationMode === 'server' ? rowCount : undefined}
        rows={rows}
        showCellVerticalBorder
        showColumnVerticalBorder
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        slots={{
          toolbar: () => (
            <TableToolbar
              loading={isDownloading}
              onDowload={hidePrices || rows.length === 0 ? undefined : () => onDowload?.()}
              subtitle={t('pages.organization.billing.scannerTable.header.title', {
                count: rowCount,
              })}
              title={title}
            />
          ),
          noRowsOverlay: () => (
            <Typography align="center" paddingTop={4}>
              {t('pages.organization.billing.noData')}
            </Typography>
          ),
          noResultsOverlay: () => (
            <Typography align="center" paddingTop={4}>
              {t('pages.organization.billing.noData')}
            </Typography>
          ),
        }}
        sx={tableStyles}
      />
    </Box>
  );
};
