import type { GridSortModel } from '@mui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';
import { useQueryParams, NumberParam, StringParam, withDefault } from 'use-query-params';

import { convertStringToSortModel } from '../utils/convertStringToSortModel';
import { serializeSortModel } from '../utils/serializeSortModel';

export const useOrganizationsUrlSync = () => {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 100),
    name: StringParam,
    sort: StringParam,
  });

  const { page, size, name, sort } = query;

  const sortModel: GridSortModel = useMemo(() => convertStringToSortModel(sort || ''), [sort]);

  const handlePageChange = useCallback(
    (newPage: number) => setQuery({ page: newPage }),
    [setQuery],
  );

  const handleSizeChange = useCallback(
    (newSize: number) => setQuery({ size: newSize }),
    [setQuery],
  );

  const handleSortModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      setQuery({ sort: serializeSortModel(newSortModel) });
    },
    [setQuery],
  );

  const handleNameChange = useCallback(
    (newSearchName: string) => setQuery({ name: newSearchName }),
    [setQuery],
  );

  return {
    page,
    size: size,
    sortModel,
    name: name || '',
    handlePageChange,
    handleSizeChange,
    handleSortModelChange,
    handleNameChange,
  };
};
