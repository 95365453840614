import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useUsersApi } from '@hooks/api/users';
import type { UserWithOrganization, User } from '@models/users';

export const useFormUserState = () => {
  const { data: usersData } = useUsersApi();
  const { state } = useLocation();
  const { id } = useParams();

  const user = useMemo((): User | UserWithOrganization | undefined => {
    if (state) return (state as { user?: UserWithOrganization })?.user;

    return usersData?.content.find((user) => user.id === id);
  }, [id, state, usersData?.content]);

  return { user };
};
