import { BriefcaseOutline, Checkmark, XOutline } from '@deepup/icons';
import { Box, Tooltip } from '@mui/material';
import {
  DataGridPro,
  type GridColDef,
  type GridRowsProp,
  type GridRowParams,
  type GridRenderCellParams,
  type GridSortModel,
  type GridFilterModel,
  type GridPaginationModel,
  type GridColumnHeaderParams,
} from '@mui/x-data-grid-pro';
import { useState, type FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { renderCellContent } from '@components/TableCell/TableCell';
import type { ProjectWithOrganizations } from '@hooks/api/projects';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { usePlatformPermissions } from '@hooks/usePlatformPermissions';
import type { Organization } from '@models/organizations';
import { getLocaleTextForDataGrid } from '@utils/getLocaleTextForDataGrid';

interface ProjectsTableProps {
  projects: ProjectWithOrganizations[];
  isLoading: boolean;
  page: number;
  size: number;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
  sortModel: GridSortModel;
  rowCount: number;
  searchName?: string;
  networkOperatorId?: string;
  generalContractorId?: string;
}

export const ProjectsTable: FC<ProjectsTableProps> = ({
  projects,
  isLoading,
  page,
  size,
  sortModel,
  rowCount,
  onSizeChange,
  onPageChange,
  onSortModelChange,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isItemVisible } = useRenderConfig();
  const pageSizeOptions = [25, 50, 100];
  const { isPlatformAdmin } = usePlatformPermissions();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const paginationModel = {
    page: page,
    pageSize: size,
  };

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    onPageChange(newModel.page);
    onSizeChange(newModel.pageSize);
  };

  const handleSortModelChange = useCallback(onSortModelChange, [onSortModelChange]);

  const renderIcon = (params: GridRenderCellParams) => {
    const project = params.row as ProjectWithOrganizations;
    const key = params.field as keyof ProjectWithOrganizations;
    const orga = project[key] as Organization | undefined;

    if (!orga) return null;

    const highlightField = orga.id === project.organization?.id;
    const hasClientInfoIcon = isItemVisible('projects.overview.components.clientIcon');

    return (
      <>
        {highlightField && hasClientInfoIcon && (
          <>
            <Tooltip
              arrow
              title={t('pages.projects.overview.components.table.tooltipProjectOwner')}
            >
              <Box>
                <BriefcaseOutline height={24} width={24} />
              </Box>
            </Tooltip>
            <Box mx={0.75} />
          </>
        )}
        {orga.name}
      </>
    );
  };

  // Column width configuration
  const columnWidths = {
    name: 350,
    organization: 250,
    networkProvider: 250,
    generalContractor: 250,
    startDate: 150,
    customerContactPerson: 250,
    contactPerson: 250,
    customerManager: 250,
    serviceProviders: 250,
    networkDesignState: 200,
    networkDesignFormat: 200,
    activityState: 150,
    pipeDirection: 150,
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('entities.projects.fields.name.label'),
      flex: 1.4,
      minWidth: columnWidths.name,
      sortable: true,
      renderHeader: (params) => (
        <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'projects',
          params,
          isLoading,
          columnField: 'name',
        }),
    },
    {
      field: 'organization',
      headerName: t('entities.projects.fields.client.label'),
      flex: 1,
      minWidth: columnWidths.organization,
      sortable: true,
      valueGetter: (_, row) => row.organization?.name || '',
      renderHeader: (params) => (
        <span data-testid="table-header-cell-Organization">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'projects',
          params,
          isLoading,
          columnField: 'organization',
        }),
    },
    {
      field: 'networkProvider',
      headerName: t('entities.projects.fields.networkProvider.label'),
      flex: 1,
      minWidth: columnWidths.networkProvider,
      sortable: true,
      renderHeader: (params) => (
        <span data-testid="table-header-cell-NetworkProvider">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'projects',
          params,
          isLoading,
          columnField: 'networkProvider',
          renderContent: (value) => renderIcon({ ...params, value }),
        }),
    },
    {
      field: 'generalContractor',
      headerName: t('entities.projects.fields.generalContractor.label'),
      flex: 1,
      minWidth: columnWidths.generalContractor,
      sortable: true,
      renderHeader: (params) => (
        <span data-testid="table-header-cell-GeneralContractor">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'projects',
          params,
          isLoading,
          columnField: 'networkProvider',
          renderContent: (value) => renderIcon({ ...params, value }),
        }),
    },
    {
      field: 'startDate',
      headerName: t('entities.projects.fields.startDate.label'),
      flex: 0.6,
      minWidth: columnWidths.startDate,
      sortable: true,
      valueGetter: (_, row) =>
        row.period?.startDate
          ? new Date(row.period.startDate).toLocaleDateString(undefined, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          : '',
      renderHeader: (params) => (
        <span data-testid="table-header-cell-Start-Date">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'projects',
          params,
          isLoading,
          columnField: 'startDate',
        }),
    },

    ...(isPlatformAdmin
      ? [
          {
            field: 'customerContactPerson',
            headerName: t('entities.projects.fields.customerContactPerson.label'),
            flex: 1,
            minWidth: columnWidths.customerContactPerson,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'customerContactPerson',
              }),
          },
          {
            field: 'contactPerson',
            headerName: t('entities.projects.fields.contactPerson.label'),
            flex: 1,
            minWidth: columnWidths.contactPerson,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'contactPerson',
              }),
          },
          {
            field: 'customerManager',
            headerName: t('pages.projects.form.header.customerManager'),
            flex: 1,
            minWidth: columnWidths.customerManager,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'customerManager',
              }),
          },
          {
            field: 'serviceProviders',
            headerName: t('entities.projects.fields.serviceProviders.label'),
            flex: 1,
            minWidth: columnWidths.serviceProviders,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-ServiceProviders">
                {params.colDef.headerName}
              </span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'serviceProviders',
                renderContent: () => (
                  <Box>{params.row.serviceProviders?.map(({ name }) => name).join(', ')}</Box>
                ),
              }),
          },
          {
            field: 'networkDesignState',
            headerName: t('pages.projects.overview.components.table.networkDesignState'),
            flex: 0.8,
            minWidth: columnWidths.networkDesignState,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'networkDesignState',
              }),
          },
          {
            field: 'networkDesignFormat',
            headerName: t('pages.projects.overview.components.table.networkDesignFormat'),
            flex: 0.8,
            minWidth: columnWidths.networkDesignFormat,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'networkDesignFormat',
              }),
          },
          {
            field: 'activityState',
            headerName: t('pages.projects.overview.components.table.activityState'),
            flex: 0.6,
            minWidth: columnWidths.activityState,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'activityState',
              }),
          },
          {
            field: 'pipeDirection',
            headerName: t('entities.projects.fields.pipeDirection.label'),
            flex: 0.5,
            minWidth: columnWidths.pipeDirection,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'pipeDirecetion',
                renderContent: () => (
                  <Box justifyContent="center" width={100}>
                    {params.row.pipeDirection ? (
                      <Checkmark height={20} width={20} />
                    ) : (
                      <XOutline height={20} width={20} />
                    )}
                  </Box>
                ),
              }),
          },
        ]
      : []),
  ];

  const rows: GridRowsProp = projects.map((project) => ({
    id: project.id,
    name: project.name,
    organization: project.organization,
    networkProvider: project.networkProvider,
    generalContractor: project.generalContractor,
    period: project.period,
    customerContactPerson: project.customerContactPerson?.name,
    contactPerson: project.contactPerson?.name,
    networkDesignState: project.networkDesign?.state,
    networkDesignFormat: project.networkDesign?.format,
    customerManager: project.customerManager?.name,
    activityState: project.activityState,
    pipeDirection: project.pipeDirection,
    serviceProviders: project.serviceProviders,
  }));

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/projects/${params.id}/overview`, {
      state: {
        project: projects.find((project) => project.id === params.id),
      },
    });
  };

  return (
    <Box overflow="auto" width="100%">
      <DataGridPro
        columns={columns}
        data-testid="table-projects"
        disableColumnMenu
        filterMode="server"
        filterModel={filterModel}
        loading={isLoading}
        localeText={getLocaleTextForDataGrid(i18n.language)}
        onFilterModelChange={setFilterModel}
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={handleRowClick}
        onSortModelChange={handleSortModelChange}
        pageSizeOptions={pageSizeOptions}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        rows={rows}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        sortModel={sortModel}
        sortingMode="server"
        sortingOrder={['asc', 'desc']}
        sx={{
          minHeight: 400,
          '& .MuiDataGrid-main': {
            minHeight: 400,
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: 400,
          },
        }}
      />
    </Box>
  );
};
