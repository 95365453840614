import { useCallback } from 'react';

import { buildBody } from '@hooks/api/utils';
import { useEnvironment } from '@hooks/useEnvironment';
import type { PlatformAdministratorFormValues } from '@pages/Platform/PlatformAdministratorEdit/PlatformAdministratorForm';

import { useJsonFetch } from '../useJsonFetch';

export const usePlatformAdminCreateApi = () => {
  const { apiUrl } = useEnvironment();
  const jsonFetch = useJsonFetch(true, apiUrl);

  const fetcher = useCallback(
    (data: PlatformAdministratorFormValues) =>
      jsonFetch('/internal-api/organization-management/v1/platform/administrator', {
        method: 'POST',
        body: buildBody(data),
      }),
    [jsonFetch],
  );

  return fetcher;
};
