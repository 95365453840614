import useSWR from 'swr';

import { type PaginatedResponse, useJsonFetch } from '@hooks/api/useJsonFetch';
import type { Organization } from '@models/organizations';

interface UseOrganizationsApiOptions {
  isDeepUpOrg?: boolean;
}

export const useOrganizationsApi = (options?: UseOrganizationsApiOptions) => {
  let endpoint = `/organizations?size=1000`;

  if (options?.isDeepUpOrg !== undefined) {
    endpoint += `&isDeepUpOrg=${options.isDeepUpOrg}`;
  }

  const jsonFetch = useJsonFetch<PaginatedResponse<Organization>>();
  const response = useSWR(endpoint, jsonFetch);

  return response;
};

export const useOrganizationByIdApi = ({ organizationId }: { organizationId?: string }) => {
  const endpoint = `/organizations/${organizationId}`;
  const jsonFetch = useJsonFetch<Organization>();

  return useSWR(endpoint, jsonFetch);
};
