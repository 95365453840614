import { Hourglass, PersonPlusOutline } from '@deepup/icons';
import { GroupAddOutlined } from '@mui/icons-material';
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGridPro, type GridColDef, type GridRowsProp } from '@mui/x-data-grid-pro';
import { useCallback, useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { renderCellContent } from '@components/TableCell/TableCell';
import { useGroupsPermissionsApi, type UserPermission } from '@hooks/api/groups';
import type { UserWithOrganization } from '@hooks/api/users';
import { hasOpenOrgInvitations } from '@utils/user';

interface AllUsersTableProps {
  users: UserWithOrganization[];
  selectedUser: UserPermission[];
  searchString?: string;
  searchOrganizationId?: string;
  onAddUser: (usersToAdd: UserWithOrganization[]) => void;
  isLoading: boolean;
}

export const AllUsersTable: FC<AllUsersTableProps> = ({
  searchString,
  searchOrganizationId,
  onAddUser,
  selectedUser,
  users = [],
  isLoading,
}) => {
  const { t } = useTranslation();
  const { isGroupAdmin } = useGroupsPermissionsApi();
  const canEdit = isGroupAdmin;
  const theme = useTheme();

  const filteredUser = useMemo(() => {
    return users.filter((user) => {
      let include = true;

      if (selectedUser.length > 0)
        include = !selectedUser.find((userPermission) => userPermission.user?.id === user.id);

      if (include && !!searchString) {
        const userNameLowerCase = user.name?.toLowerCase();

        const searchStringLowerCase = searchString.toLowerCase();

        include = userNameLowerCase ? userNameLowerCase.indexOf(searchStringLowerCase) > -1 : false;

        if (!include) {
          const userEmailLowerCase = user.email.toLowerCase();

          include = userEmailLowerCase.indexOf(searchStringLowerCase) > -1;
        }
      }

      if (include && searchOrganizationId !== '')
        include = user.organizationId === searchOrganizationId;

      return include;
    });
  }, [users, searchString, selectedUser, searchOrganizationId]);

  const handleAddAllUsers = useCallback(() => {
    onAddUser(filteredUser);
  }, [filteredUser, onAddUser]);

  const handleAddUser = useCallback(
    (user: UserWithOrganization) => () => {
      onAddUser([user]);
    },
    [onAddUser],
  );

  const getCellStyle = useCallback(
    (user: UserWithOrganization) => {
      return hasOpenOrgInvitations(user.organizationRelations)
        ? { color: theme.palette.text.disabled }
        : { color: theme.palette.text.primary };
    },
    [theme],
  );

  const columns: GridColDef<UserWithOrganization>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('entities.user.fields.name.label'),
        flex: 2,
        renderCell: (params) => {
          const user = params.row;
          const hasInvitations = hasOpenOrgInvitations(user.organizationRelations);

          return (
            <Box alignItems="center" display="flex" height={'100%'}>
              {hasInvitations && (
                <Tooltip arrow title={t('common.waitingForRegistration')}>
                  <Hourglass fill={theme.palette.text.disabled} height={24} width={24} />
                </Tooltip>
              )}
              <Typography style={getCellStyle(user)}>
                {renderCellContent({
                  entityType: 'users',
                  params,
                  isLoading,
                  columnField: 'name',
                })}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: 'email',
        flex: 2,
        headerName: t('entities.user.fields.email.label'),
        renderCell: (params) => {
          const user = params.row;

          return renderCellContent({
            entityType: 'users',
            params,
            isLoading,
            columnField: 'email',
            renderContent: () => <Typography style={getCellStyle(user)}>{params.value}</Typography>,
          });
        },
        sortable: true,
      },
      {
        field: 'workPosition',
        flex: 1,
        headerName: t('entities.user.fields.workPosition.label'),
        renderCell: (params) => {
          const user = params.row;

          return renderCellContent({
            entityType: 'users',
            params,
            isLoading,
            columnField: 'workPosition',
            renderContent: () => <Typography style={getCellStyle(user)}>{params.value}</Typography>,
          });
        },
        sortable: true,
      },
      {
        field: 'organization',
        flex: 1,
        headerName: t('entities.user.fields.organization.label'),
        renderCell: (params) => {
          const user = params.row;

          return renderCellContent({
            entityType: 'users',
            params,
            isLoading,
            columnField: 'organization',
            renderContent: () => <Typography style={getCellStyle(user)}>{params.value}</Typography>,
          });
        },
        sortable: true,
      },
      {
        field: 'addAction',
        headerName: '',
        flex: 1.5,
        renderHeader: () => (
          <Box display="flex" justifyContent="flex-end" width="100vw">
            <Button
              color="secondary"
              disabled={filteredUser.length === 0 || !canEdit}
              onClick={handleAddAllUsers}
              startIcon={<GroupAddOutlined />}
              variant="text"
            >
              {t('pages.groupsPermissions.create.components.btnAddAllUsers')}
            </Button>
          </Box>
        ),
        renderCell: (params) => (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button
              color="secondary"
              disabled={!canEdit}
              onClick={handleAddUser(params.row)}
              startIcon={<PersonPlusOutline fill={'currentColor'} />}
              variant="text"
            >
              {t('pages.groupsPermissions.create.components.btnAddToGroup')}
            </Button>
          </Box>
        ),
        sortable: false,
      },
    ],
    [
      t,
      theme.palette.text.disabled,
      getCellStyle,
      isLoading,
      filteredUser.length,
      canEdit,
      handleAddAllUsers,
      handleAddUser,
    ],
  );

  const rows: GridRowsProp = filteredUser.map((user) => ({
    ...user,
    id: user.id,
    name: user.name,
    email: user.email,
    workPosition: user.workPosition,
    organization: user.organization?.name,
  }));

  return (
    <DataGridPro
      autoHeight
      columns={columns}
      disableColumnMenu
      disableRowSelectionOnClick
      loading={isLoading}
      pagination
      rows={rows as UserWithOrganization[]}
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
      sortingOrder={['asc', 'desc']}
    />
  );
};
