import { DateTime } from 'luxon';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { SelectItem } from '@components/FormElements';
import { useProjectsApi } from '@hooks/api/projects';
import type { Project } from '@hooks/api/projects/types';

/**
 * Custom hook to fetch and manage projects for a specific organization for the billing dashboard.
 *
 * Fetches projects using `useProjectsApi` and filters them based on the provided organization ID.
 * While projects are loading, `projectList` will be `null`. Once loaded, it will contain the list of projects
 * associated with the organization, formatted as `SelectItem` for UI components like dropdowns.
 *
 * @param {string} organizationId - The ID of the organization to fetch projects for.
 * @returns {object} An object containing:
 *   - `setSelectedProjects`: A function to set the selected projects state.
 *   - `selectedProjects`: An array of currently selected project SelectItems or null.
 *   - `projectList`: An array of project SelectItems for the organization, or null if projects are still loading.
 *   - `isLoading`: A boolean indicating whether the projects data is currently being loaded.
 */
export const useProjects = (organizationId: string) => {
  const [selectedProjects, setSelectedProjects] = useState<SelectItem[] | null>(null);

  const { data: projectsData, isLoading } = useProjectsApi();

  const orgProjects = useMemo(() => {
    if (isLoading) return null; // Indicate loading state by returning null projectList

    const projectList = projectsData?.content || ([] as Project[]);

    return projectList
      .filter((item) => item.organizationId === organizationId)
      .map((item) => ({
        id: item.id,
        name: item.name,
      }));
  }, [isLoading, projectsData?.content, organizationId]);

  return {
    setSelectedProjects,
    selectedProjects,
    projectList: orgProjects,
    isLoading,
  };
};

/**
 * Generates an array of month objects, starting from April 2024 up to the current month.
 *
 * Each month object in the array has an 'id' and a 'name' property.
 * The 'id' is a string representing the start and end ISO dates of the month, separated by an underscore (e.g., "2024-04-01T00:00:00.000Z_2024-04-30T23:59:59.999Z").
 * The 'name' is a formatted string of the month and year, localized based on the provided 'language' (e.g., "April/2024").
 *
 * @param {string} language - The language code to use for formatting the month name (e.g., 'en', 'fr').
 * @returns {SelectItem[]} An array of SelectItem objects representing the generated months.
 */
const generateMonths = (language: string) => {
  // We only have data from April 2024
  const startMonth = DateTime.utc(2024, 4, 1); // April 2024 in UTC
  const currentMonth = DateTime.utc().startOf('month'); // Current month in UTC

  const months = [];

  let date = startMonth;

  while (date <= currentMonth) {
    const startOfMonth = date.startOf('month').toISO();
    const endOfMonth = date.endOf('month').toISO();

    months.unshift({
      id: `${startOfMonth}_${endOfMonth}`,
      name: date.setLocale(language).toFormat('LLLL/yyyy'),
    });

    date = date.plus({ months: 1 });
  }

  return months;
};

/**
 * Custom hook to manage the selected month and provide a list of available months.
 *
 * @returns {object} An object containing:
 *   - `month`: The currently selected month SelectItem object.
 *   - `setMonth`: A function to set the selected month by its 'id'.
 *   - `monthList`: An array of SelectItem objects representing the list of available months.
 */
export const useMonths = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const monthList = generateMonths(language);
  const [month, setMonth] = useState<SelectItem>(monthList[0]);

  return {
    month,
    setMonth: (id: string) => {
      const nextMonth = monthList.find((item) => item.id === id) || monthList[0];

      setMonth(nextMonth);
    },
    monthList,
  };
};
