import { CircleQuestionmarkOutline } from '@deepup/icons';
import { Checkbox, Typography, Tooltip, FormControlLabel, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import type {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormSectionHeader, OrganizationSelect } from '@components/FormElements';
import { useRenderConfig } from '@hooks/ui';
import { useIsLoggedInUser } from '@hooks/useIsLoggedInUser';
import { PlatformUserType, usePlatformPermissions } from '@hooks/usePlatformPermissions';
import type { Organization } from '@models/organizations';
import { useFormUserState } from '@pages/UserManagement/useFormUserState';
import { OrgaAdminInfo } from '@pages/common';

import { InvoiceToolAdminInfo } from './InvoiceToolAdminInfo';

interface UserFormConnectionsProps<T extends FieldValues> {
  organizations: Organization[];
  control: Control<T, object>;
  disabled: boolean;
  formErrors: FieldErrorsImpl<DeepRequired<T>>;
  register: UseFormRegister<T>;
  hasOrgaAdminOption?: boolean;
  hasInvoiceToolAdminOption?: boolean;
}

export const UserFormConnections = <T extends FieldValues>({
  organizations,
  control,
  formErrors,
  register,
  hasOrgaAdminOption = true,
  hasInvoiceToolAdminOption,
}: UserFormConnectionsProps<T>) => {
  const { t } = useTranslation();
  const { user } = useFormUserState();
  const {
    isAdmin,
    isAdminOfOrg,
    permissions: { organizationId: adminOrganizationId },
  } = usePlatformPermissions();
  const { isItemEnabled } = useRenderConfig();
  const { isLoggedInUser } = useIsLoggedInUser();

  const defaultOrganizationId = user?.organizationId ?? adminOrganizationId;
  const userDefaultOrganizationRelation = user?.organizationRelations.find(
    ({ id }) => id === defaultOrganizationId,
  );

  const canSelectOrganization = isItemEnabled('user.create.components.organizationSelect');

  const isOwnUser = isLoggedInUser(user);
  const isAdminOfUsersOrg = !user || isAdminOfOrg(user.organizationId);
  const canEditOrgaAdminOption = isAdminOfUsersOrg && !isOwnUser;
  const canEditInvoiceToolAdminOption = isAdminOfUsersOrg;

  return (
    <Box>
      <FormSectionHeader title={t('pages.user.form.header.connections')} />
      <OrganizationSelect
        control={control}
        defaultValue={defaultOrganizationId}
        disabled={!canSelectOrganization}
        error={formErrors?.organizationId}
        isRequired
        items={organizations}
        label={t('entities.user.fields.organization.label')}
      />
      {hasOrgaAdminOption && (
        <Stack marginBottom={4}>
          <FormControlLabel
            control={
              <Checkbox
                {...register('isAdmin' as Path<T>)}
                defaultChecked={user?.platformUserType === PlatformUserType.organizationAdmin}
                disabled={!canEditOrgaAdminOption}
              />
            }
            label={
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                {t('entities.user.fields.isOrganizationAdmin.label')}
                <Box component="span" mx={0.5} />
                <Tooltip
                  arrow
                  title={
                    canEditOrgaAdminOption || !isAdmin ? (
                      <OrgaAdminInfo />
                    ) : (
                      t('entities.user.fields.isOrganizationAdmin.disabledHelp')
                    )
                  }
                >
                  <Box
                    alignItems="center"
                    component="span"
                    display="flex"
                    sx={{ cursor: 'pointer' }}
                  >
                    <CircleQuestionmarkOutline height={24} width={24} />
                  </Box>
                </Tooltip>
              </Typography>
            }
          />
          {hasInvoiceToolAdminOption && (
            <FormControlLabel
              control={
                <Checkbox
                  {...register('isInvoiceToolAdmin' as Path<T>)}
                  defaultChecked={userDefaultOrganizationRelation?.isInvoiceToolAdmin}
                  disabled={!canEditInvoiceToolAdminOption}
                />
              }
              label={
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  {t('entities.user.fields.isInvoiceToolAdmin.label')}
                  <Box mx={0.5} />
                  <Tooltip
                    arrow
                    title={
                      canEditInvoiceToolAdminOption || !isAdmin ? (
                        <InvoiceToolAdminInfo />
                      ) : (
                        t('entities.user.fields.isInvoiceToolAdmin.disabledHelp')
                      )
                    }
                  >
                    <Box alignItems="center" display="flex" sx={{ cursor: 'pointer' }}>
                      <CircleQuestionmarkOutline height={24} width={24} />
                    </Box>
                  </Tooltip>
                </Typography>
              }
            />
          )}
        </Stack>
      )}
    </Box>
  );
};
