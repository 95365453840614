import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { useState, type ChangeEvent, type FC, type SyntheticEvent } from 'react';

import type { UserPermission } from '@hooks/api/groups';
import type { User } from '@hooks/api/users';
import type { Organization } from '@models/organizations';

import { AllUsersTable } from './AllUsersTable';
import { SearchUserInput } from './SearchUserInput';
import { SelectUserOrganizationAutocomplete } from './SelectUserOrganizationAutocomplete';

interface AllUsersTableContainerProps {
  allOrganizations: Organization[];
  selectedUser: UserPermission[];
  allUser: User[];
  hideOrganizationSelect?: boolean;
  onChangeUserPermissionConfiguration: (userToUpdate: UserPermission[]) => void;
  isLoading: boolean;
}

export const AllUsersTableContainer: FC<AllUsersTableContainerProps> = ({
  allOrganizations,
  allUser,
  selectedUser,
  hideOrganizationSelect,
  onChangeUserPermissionConfiguration,
  isLoading,
}) => {
  const [searchName, setSearchName] = useState<string>('');
  const [searchOrganizationId, setSearchOrganizationId] = useState<string>('');

  const handleAddUser = (users: User[]) => {
    const newUserPermissions: UserPermission[] = users.map((user) => {
      const newUserPermission = {
        userId: `${user.connection}|${user.id}`,
        id: user.id,
        user,
        isGroupAdmin: false,
      };

      return newUserPermission;
    });

    onChangeUserPermissionConfiguration([...selectedUser, ...newUserPermissions]);
  };

  const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const searchString = event.currentTarget.value;

    if (searchString.length > 1 || searchString.length === 0) {
      setSearchName(searchString);
    }
  };

  const handleChangeSearchOrganization = (
    _: SyntheticEvent<Element, Event>,
    value?: { id: string; name: string } | null,
  ) => {
    setSearchOrganizationId(value?.id ?? '');
  };

  return (
    <Stack>
      <Box display="flex" gap={2} mb={4}>
        <SearchUserInput isLoading={isLoading} onChangeSearchText={handleChangeSearchText} />
        <SelectUserOrganizationAutocomplete
          isLoading={isLoading}
          isVisible={!hideOrganizationSelect}
          onChangeOrganization={handleChangeSearchOrganization}
          options={allOrganizations}
        />
      </Box>
      <AllUsersTable
        isLoading={isLoading}
        onAddUser={handleAddUser}
        searchOrganizationId={searchOrganizationId}
        searchString={searchName}
        selectedUser={selectedUser}
        users={allUser}
      />
    </Stack>
  );
};
