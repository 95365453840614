import { useCallback } from 'react';
import type { FieldValues } from 'react-hook-form';

import { useJsonFetch } from '@hooks/api/useJsonFetch';
import { buildBody } from '@hooks/api/utils';
import type { UserRegistrationResponse } from '@models/users';

export const useUserRegisterApi = () => {
  const jsonFetch = useJsonFetch<UserRegistrationResponse>(false);

  const fetcher = useCallback(
    (user: FieldValues) =>
      jsonFetch('/users/register', {
        method: 'POST',

        body: buildBody(user),
      }),
    [jsonFetch],
  );

  return fetcher;
};
