import { Checkmark, Hourglass, Minus, OfficeBuildingCrossedOutline } from '@deepup/icons';
import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGridPro, type GridColDef, type GridCellParams } from '@mui/x-data-grid-pro';
import { useState, useCallback, useMemo, type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EmptyTableInfo } from '@components/PageEntityTable/EmptyTableInfo';
import { renderCellContent } from '@components/TableCell/TableCell';
import { useGroupsPaginatedApi } from '@hooks/api/groups/useGroupsPaginatedApi';
import {
  useOrganizationsApiTotalCount,
  useOrganizationsPaginatedApi,
} from '@hooks/api/organizations';
import { useUserProjectPermissions } from '@hooks/api/users';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import type { OrganizationUserPermission } from '@models/users';

import { useFormUserState } from '../useFormUserState';

export const UserManagementPermissions: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showMessage } = useSnackbarMessage();
  const { user } = useFormUserState();
  const getPermissionsForUser = useUserProjectPermissions();
  const [permissions, setPermissions] = useState<OrganizationUserPermission[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const totalGroups = useGroupsPaginatedApi({ page: 0, size: 1 }).data?.totalElements;
  const groups = useGroupsPaginatedApi({ page: 0, size: totalGroups || 0 });
  const totalOrganizations = useOrganizationsApiTotalCount();
  const organizations = useOrganizationsPaginatedApi({ page: 0, size: totalOrganizations || 0 });
  const theme = useTheme();
  const iconSize = useMemo(() => theme.spacing(3), [theme]);

  useEffect(() => {
    const fetchPermissions = async () => {
      if (user?.id) {
        setIsLoading(true);
        try {
          const userPermissions = await getPermissionsForUser({
            id: user.id,
            connection: user.connection,
          });

          setPermissions(userPermissions);
        } catch (error) {
          console.error('Failed to fetch permissions:', error);
          showMessage({ message: `${error}`, type: 'error' });
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchPermissions();
  }, [user, getPermissionsForUser, showMessage]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'projectName',
        headerName: t('common.project'),
        flex: 1.5,
        renderCell: (params) =>
          renderCellContent({
            entityType: 'project',
            params,
            isLoading,
            columnField: 'projectName',
          }),
      },
      {
        field: 'groupName',
        headerName: t('common.group'),
        flex: 1.5,
        renderCell: (params) =>
          renderCellContent({
            entityType: 'group',
            params,
            isLoading,
            columnField: 'groupName',
            renderContent: (value: string) => (
              <Typography color="primary" variant="body2">
                {value}
              </Typography>
            ),
          }),
      },
      {
        field: 'hasDocumentation',
        headerName: t('pages.groupsPermissions.create.components.header.documentation'),
        flex: 1,
        type: 'boolean',
        renderCell: (params) =>
          renderCellContent({
            entityType: 'documentation',
            params,
            isLoading,
            columnField: 'hasDocumentation',
            renderContent: (value) =>
              value ? (
                <Checkmark height={iconSize} width={iconSize} />
              ) : (
                <Minus height={iconSize} width={iconSize} />
              ),
          }),
        align: 'center',
      },
      {
        field: 'hasMonitoring',
        headerName: t('pages.groupsPermissions.create.components.header.monitoring'),
        flex: 1,
        type: 'boolean',
        renderCell: (params) =>
          renderCellContent({
            entityType: 'monitoring',
            params,
            isLoading,
            columnField: 'hasMonitoring',
            renderContent: (value) =>
              value ? (
                <Checkmark height={iconSize} width={iconSize} />
              ) : (
                <Minus height={iconSize} width={iconSize} />
              ),
          }),
        align: 'center',
      },
    ],
    [iconSize, isLoading, t],
  );

  const handleCellClick = useCallback(
    (params: GridCellParams) => {
      const [groupId] = params.id.toString().split('#');
      const groupsArray = groups.data?.content;
      const orgsArray = organizations.data?.content;

      if (!groupsArray || !orgsArray) return;

      const clickedGroup = groupsArray.find((group) => group.id === groupId);

      const clickedGroupOrg = orgsArray.find(
        (organization) => organization.id === clickedGroup?.organizationId,
      );

      const route = clickedGroupOrg?.isDeepUpOrg
        ? `/deepup-groups/${groupId}/overview`
        : `/groups/${groupId}/overview`;

      navigate(route);
    },
    [groups.data?.content, navigate, organizations.data?.content],
  );

  return (
    <Stack spacing={3}>
      {permissions.map((permission) => (
        <Box key={permission.organizationName}>
          <Stack alignItems="center" direction="row" mb={1} spacing={1}>
            <Typography mb={2} variant="h6">
              {!permission.hasConsentWithOrganization ? (
                <Tooltip placement="bottom" title={t('common.waitingForRegistration')}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Hourglass />
                    <span style={{ color: '#aaa' }}>{permission.organizationName}</span>
                  </Box>
                </Tooltip>
              ) : (
                permission.organizationName
              )}
            </Typography>
            {permission.isExternalOrganization && (
              <OfficeBuildingCrossedOutline height={iconSize} width={iconSize} />
            )}
          </Stack>
          <DataGridPro
            autoHeight
            columns={columns}
            getRowId={(row) => row.projectId}
            loading={isLoading}
            onCellClick={handleCellClick}
            pagination
            rows={permission.permissions}
            slotProps={{
              loadingOverlay: {
                variant: 'skeleton',
                noRowsVariant: 'skeleton',
              },
            }}
          />
        </Box>
      ))}
      {!permissions.length && !isLoading && (
        <EmptyTableInfo info={t('pages.user.edit.components.table.emptyInfo')} />
      )}
    </Stack>
  );
};
