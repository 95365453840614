import type { Organization } from '@models/organizations';

export function getOrganizationById(organizations: Organization[], id?: string) {
  return organizations.find((organization) => organization.id === id);
}

export function filterOrganizationsByDeepUpOrg(
  organization: Organization[],
  includeOrExclude: 'include' | 'exclude',
) {
  return organization.filter((organization) => {
    if (includeOrExclude === 'include') return !!organization.isDeepUpOrg;
    if (includeOrExclude === 'exclude') return !organization.isDeepUpOrg;
  });
}
