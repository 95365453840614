import '@utils/sentrySetup';
import { LicenseInfo } from '@mui/x-license';
import { createRoot } from 'react-dom/client';
import './main.css';
import './i18n.config';

import { App } from '@components/App';
import { useEnvironment } from '@hooks/useEnvironment';

const renderApp = () => createRoot(document.getElementById('root') as HTMLElement).render(<App />);

const enableMocking = async () => {
  try {
    const { worker } = await import('./__mocks__/browser');

    return worker.start();
  } catch (error) {
    console.error(`Mock Service Worker could not be imported: ${error}`);
  }
};

const mockingEnabled = import.meta.env.DEV && import.meta.env.VITE_MOCKING_ENABLED === 'true';

// eslint-disable-next-line react-hooks/rules-of-hooks
LicenseInfo.setLicenseKey(useEnvironment().muiProLicenceKey);

if (mockingEnabled) {
  // mock api must be enabled before app is rendered
  enableMocking().then(renderApp);
} else {
  // render app normally
  renderApp();
}
