import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { type FC, useState, useEffect, type FormEvent } from 'react';
import { useForm, useFormState, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BottomFormNavigation } from '@components/BottomFormNavigation';
import { useOrganizationsApi } from '@hooks/api/organizations';
import type { User } from '@hooks/api/users';
import { useRenderConfig } from '@hooks/ui';
import { usePlatformPermissions } from '@hooks/usePlatformPermissions';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import type { ExternalUser } from '@models/users';

import {
  NotCopiedWarningDialog,
  UserFormAccount,
  UserFormAdvancedSettings,
  UserFormConnections,
} from './UserFormSections';
import { externalUserFormSchema } from './schema';

interface ExternalUserFormProps {
  user?: User;
  onSubmit: (data: FieldValues) => Promise<void>;
}

// Container component for managing the whole form state.
export const ExternalUserCreateForm: FC<ExternalUserFormProps> = ({ onSubmit, user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, error } = useOrganizationsApi({ isDeepUpOrg: false });
  const {
    isPlatformAdmin,
    permissions: { organizationId: adminOrganizationId },
  } = usePlatformPermissions();

  const userFormSchemaWithTranslations = externalUserFormSchema({
    error: {
      email: t('entities.user.fields.email.error'),
      invalidEmail: t('entities.user.fields.invalidEmail.error'),
      organization: t('entities.user.fields.organization.error'),
      userLanguage: t('entities.user.fields.userLanguage.error'),
    },
  });
  const { showMessage } = useSnackbarMessage();
  const {
    register,
    handleSubmit: formSubmitHandler,
    control,
    setValue,
    getValues,
    setError,
  } = useForm<ExternalUser>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver<ExternalUser>(userFormSchemaWithTranslations),
  });
  const { errors: formErrors } = useFormState({ control });
  const { isItemEnabled, isItemVisible } = useRenderConfig();
  const hasFormEnabled = isItemEnabled('user.edit.form.mode');
  const hasAdvancedSettings = isItemVisible('user.create.components.advancedSettings');

  const [password, setPassword] = useState<string>();
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);
  const [showNotCopiedWarning, setShowNotCopiedWarning] = useState<boolean>(false);
  const [advancedSettings, setAdvancedSettings] = useState({
    isItemEnabled: false,
    isOwnPasswordSet: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // in edit mode user email is not editable, therefore it is not part of the form and we need to set it manually so form can be submitted
    if (user?.email) setValue('email', user.email);
  }, [setValue, user?.email]);

  const handleToggleAdvancedSettings = (isItemEnabled: boolean) =>
    setAdvancedSettings({
      ...advancedSettings,
      isItemEnabled,
    });

  const handleChangePassword = (newPassword: string | undefined, copiedToClipboard: boolean) => {
    // This routine is required as the form field for password is filled with a generated password
    // AND we need to know if there is a password generated (in case advanced settings are enabled, for deciding if the submit button should be re-enabled)
    // AND field values are only accessible after submit
    if (newPassword) {
      setValue('password', newPassword);
      setPassword(newPassword);
      setCopiedToClipboard(copiedToClipboard);
    }
  };

  const handleToggleSetOwnPassword = (isOwnPasswordSet: boolean) =>
    setAdvancedSettings({
      ...advancedSettings,
      isOwnPasswordSet,
    });

  const handleSubmitTry = async (data: FieldValues) => {
    if (advancedSettings.isItemEnabled && advancedSettings.isOwnPasswordSet && !password)
      return setError('password', { message: t('pages.user.form.messages.errorPassword') });

    if (advancedSettings.isOwnPasswordSet && !copiedToClipboard) {
      setShowNotCopiedWarning(true);

      return;
    }

    await onSubmit(isPlatformAdmin ? data : { ...data, organizationId: adminOrganizationId });
  };

  const handleClickCancel = () => {
    navigate('/users');
  };

  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading...</div>;

  const { content: organizations } = data;

  const formErrorHandler = (errors: FieldValues | undefined) => {
    if (errors) {
      showMessage({ message: t('common.missingData'), type: 'error' });
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    setIsSubmitting(true);
    await formSubmitHandler(handleSubmitTry, formErrorHandler)(event);
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column">
        <UserFormAccount
          control={control}
          disabled={!hasFormEnabled}
          formErrors={formErrors}
          register={register}
          user={user}
          userLanguageControlName={'userLanguage'}
        />
        <UserFormConnections
          control={control}
          disabled={!hasFormEnabled}
          formErrors={formErrors}
          hasOrgaAdminOption={false}
          organizations={organizations}
          register={register}
        />
        {hasAdvancedSettings && (
          <UserFormAdvancedSettings
            formErrors={formErrors}
            onChangeAdvancedSettingsEnabled={handleToggleAdvancedSettings}
            onChangePassword={handleChangePassword}
            onChangeSetOwnPassword={handleToggleSetOwnPassword}
            password={password}
            register={register}
            settings={advancedSettings}
          />
        )}
        <Box my={4} />
        <BottomFormNavigation
          isLoadingNext={isSubmitting}
          nextType="submit"
          onGoBack={handleClickCancel}
        />
      </Box>
      <NotCopiedWarningDialog
        onContinue={() => onSubmit(getValues())}
        password={password}
        setShowNotCopiedWarning={setShowNotCopiedWarning}
        showNotCopiedWarning={showNotCopiedWarning}
      />
    </form>
  );
};
