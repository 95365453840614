import { useCallback } from 'react';

import { useEnvironment } from '@hooks/useEnvironment';
import type { PlatformAdministratorFormValues } from '@pages/Platform/PlatformAdministratorEdit/PlatformAdministratorForm';

import { useJsonFetch } from '../useJsonFetch';

export const usePlatformAdminDeleteApi = () => {
  const { apiUrl } = useEnvironment();
  const jsonFetch = useJsonFetch(true, apiUrl);

  const fetcher = useCallback(
    (data: PlatformAdministratorFormValues) =>
      jsonFetch(
        `/internal-api/organization-management/v1/platform/administrator/${data.emailAddress}`,
        {
          method: 'DELETE',
        },
      ),
    [jsonFetch],
  );

  return fetcher;
};
