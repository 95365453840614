import Box from '@mui/material/Box';
import type { Control, DeepRequired, FieldErrorsImpl, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormSectionHeader, OrganizationSelect } from '@components/FormElements';
import type { Project } from '@hooks/api/projects';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import type { Organization } from '@models/organizations';

interface ProjectsFormConnectionsProps<T extends FieldValues> {
  organizations: Organization[];
  formErrors: FieldErrorsImpl<DeepRequired<T>>;
  project?: Project;
  control: Control<T, object>;
}

export const ProjectsFormConnections = <T extends FieldValues>({
  organizations,
  project,
  formErrors,
  control,
}: ProjectsFormConnectionsProps<T>) => {
  const { t } = useTranslation();
  const { isItemEnabled, isItemVisible } = useRenderConfig();

  const isFormEnabled = isItemEnabled('projects.edit.form.mode');
  const hasNetworkProvider = isItemVisible('projects.edit.form.fields.networkProvider');
  const hasGeneralContractor = isItemVisible('projects.edit.form.fields.generalContractor');

  return (
    <Box>
      <FormSectionHeader title={t('pages.projects.form.header.connections')} />
      <OrganizationSelect<T>
        control={control}
        defaultValue={project?.organizationId}
        disabled={!isFormEnabled || !!project}
        error={formErrors.organizationId}
        formFieldName="organizationId"
        isRequired
        items={organizations}
        label={t('entities.projects.fields.client.label')}
      />
      {hasNetworkProvider && (
        <OrganizationSelect<T>
          control={control}
          defaultValue={project?.networkProviderId}
          error={formErrors.networkProviderId}
          formFieldName="networkProviderId"
          items={organizations}
          label={t('entities.projects.fields.networkProvider.label')}
        />
      )}
      {hasGeneralContractor && (
        <OrganizationSelect<T>
          control={control}
          defaultValue={project?.generalContractorId}
          error={formErrors.generalContractorId}
          formFieldName="generalContractorId"
          items={organizations}
          label={t('entities.projects.fields.generalContractor.label')}
        />
      )}
    </Box>
  );
};
