import { date } from 'yup';

export const validateDeepupEmail = (optional: boolean) => (email?: string) => {
  if (!email) return optional;
  const validDomains = ['@deepup.de', '@deepup.ai'];

  return validDomains.some((domain) => email.endsWith(domain));
};

export const validateMultiPolygonGeoJson =
  (optional: boolean) =>
  (geoJsonString: unknown): boolean => {
    if (!geoJsonString) return optional;
    if (typeof geoJsonString !== 'string') return false;
    try {
      const { type, coordinates } = JSON.parse(geoJsonString);

      return ['MultiPolygon', 'Polygon'].includes(type) && Array.isArray(coordinates);
    } catch (e) {
      console.error(e);

      return false;
    }
  };

export const validateDate =
  (optional: boolean) =>
  async (dateString: unknown): Promise<boolean> => {
    if (!dateString) return optional;

    return date().isValid(dateString);
  };

export const isOneInputEmpty = (value: {
  name?: string | undefined;
  email?: string | undefined;
}) => {
  const { name, email } = value;

  return (name && !email) || (!name && email) ? false : true;
};
