import type { GridSortModel } from '@mui/x-data-grid-pro';

import { useApiWithOptions } from '../useApiWithOptions';
import { convertSortModelToString } from '../utils/convertSortModelToString';

import type { User } from './types';

interface UserQueryOptions {
  page: number;
  size: number;
  sortModel?: GridSortModel;
  nameOrEmail?: string;
  organizationId?: string;
}

const constructQueryStringForUser = ({
  page,
  size,
  sortModel,
  nameOrEmail,
  organizationId,
}: UserQueryOptions): string => {
  const sortParam = convertSortModelToString(sortModel);
  const queryParams = {
    page: page.toString(),
    size: size.toString(),
    ...(nameOrEmail && { nameOrEmail }),
    ...(organizationId && { organizationId }),
    ...(sortParam && { sort: sortParam }),
  };

  const searchParams = new URLSearchParams(queryParams);

  return `?${searchParams.toString()}`;
};

export const useUsersPaginatedApi = (queryOptions: UserQueryOptions) =>
  useApiWithOptions<User>({
    context: 'users',
    queryString: constructQueryStringForUser(queryOptions),
  });
