import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useFlags } from 'flagsmith/react';
import { useEffect, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { MultiSelect } from '@components/FormElements';
import {
  useApiDownloadInvoicedMeters,
  useApiDownloadScanner,
} from '@hooks/api/billing/useApiGetInvoicedMeters';
import { useDownloadHandler } from '@hooks/api/billing/useDownloadHandler';
import {
  getDateRangeFromMonthId,
  mapProjectIds,
  useInvoicedMetersData,
} from '@hooks/api/billing/useInvoicedMetersData';
import { useScannerRentData } from '@hooks/api/billing/useScannerRentData';
import { usePlatformPermissions } from '@hooks/usePlatformPermissions';

import { MeterTable, FilterSelect, useProjects, useMonths } from './OrganizationsDashboardTable';
import { ScannerTable } from './OrganizationsDashboardTable/OrganizationsEditDashboardScannerTable';
import { useFormOrganizationState } from './useFormOrganizationState';

export type DownloadType = ('SCANNER' | 'METER') & string;
export const defaultPaginationModel = { page: 0, pageSize: 5 };

export const OrganizationsEditDashboard: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { organization } = useFormOrganizationState();
  const [paginationModelMeter, setPaginationModelMeter] = useState(defaultPaginationModel);
  const [paginationModelScanner, setPaginationModelScanner] = useState(defaultPaginationModel);

  const {
    selectedProjects,
    setSelectedProjects,
    projectList,
    isLoading: isLoadingProjects,
  } = useProjects(organization.id);
  const { month, setMonth, monthList } = useMonths();
  const { isAdminOfOrg, isPlatformAdmin } = usePlatformPermissions();
  const { billing_scanner_rent_enabled } = useFlags(['billing_scanner_rent_enabled']);

  // Note: currently our api only provides valid data from January 2025.
  // Older data should not be available for the customers, but only for the platform admins.
  // Sample month.id: 2024-12-31T23:00:00.000Z_2025-01-31T22:59:59.999Z
  // We check if the year of the end date is >= 2025.
  const showRentedScannerForCustomers = Number(month.id.split('_')[1].split('-')[0]) >= 2025;

  const isRentedScannerEnabled =
    billing_scanner_rent_enabled.enabled &&
    ((showRentedScannerForCustomers && isAdminOfOrg(id)) || isPlatformAdmin);

  const { data: dataLabeledMeters, isLoading: isLoadingLabeledMeters } = useInvoicedMetersData({
    organizationId: organization.id,
    month,
    selectedProjects,
    projectList: projectList ?? [],
    paginationModel: paginationModelMeter,
  });

  const { data: dataScannerRent, isLoading: isLoadingScannerRent } = useScannerRentData({
    organizationId: organization.id,
    month,
    paginationModel: paginationModelScanner,
    isEnabled: isRentedScannerEnabled,
  });

  const { downloadId: downloadIdScanner, handleDownload: handleScannerDownload } =
    useDownloadHandler();

  const { downloadId: downloadIdMeter, handleDownload: handleMeterDownload } = useDownloadHandler();

  const { trigger: createDownloadMeter, error: downloadMeterError } = useApiDownloadInvoicedMeters({
    organizationId: organization.id,
    id: downloadIdMeter,
    dateRange: getDateRangeFromMonthId(month.id),
    projectIds: mapProjectIds(selectedProjects, projectList ?? []),
    listOptions: {
      page: paginationModelScanner.page,
      pageSize: paginationModelScanner.pageSize,
    },
  });

  const { trigger: createDownloadScanner, error: downloadScannerError } = useApiDownloadScanner({
    organizationId: organization.id,
    id: downloadIdScanner,
    dateRange: getDateRangeFromMonthId(month.id),
    listOptions: {
      page: paginationModelScanner.page,
      pageSize: paginationModelScanner.pageSize,
    },
  });

  const downloadScanner = () => handleScannerDownload(createDownloadScanner);
  const downloadMeter = () => handleMeterDownload(createDownloadMeter);

  useEffect(() => {
    setPaginationModelMeter((prev) => ({ ...prev, page: 0 }));
    setPaginationModelScanner((prev) => ({ ...prev, page: 0 }));
  }, [month]);

  if (!id || !organization) {
    return <Box>{t('pages.organization.form.info.noOrganizationGiven')}</Box>;
  }

  return (
    <Stack spacing={4}>
      <Grid container spacing={1}>
        <Grid lg={4} md={6} sm={12} xl={3} xs={12}>
          <MultiSelect
            disabled={!projectList}
            items={projectList ?? []}
            label={t('pages.projects.overview.components.filter.name')}
            selectedItems={selectedProjects ?? []}
            setSelectedItems={(projects) => {
              setSelectedProjects(projects);
            }}
          />
        </Grid>
        <Grid lg={4} md={6} sm={12} xl={3} xs={12}>
          <FilterSelect
            defaultLabel={t('pages.organization.billing.filter.month')}
            items={monthList}
            onChange={setMonth}
            value={month}
          />
        </Grid>
      </Grid>
      <MeterTable
        hasProjects={projectList ? projectList?.length > 0 : false}
        isDownloading={downloadIdMeter !== null && !downloadMeterError}
        isLoading={(isLoadingLabeledMeters || isLoadingProjects) && !downloadMeterError}
        onDowload={downloadMeter}
        onPaginationModelChange={setPaginationModelMeter}
        paginationMode="server"
        paginationModel={
          dataLabeledMeters?.paginationInfo
            ? {
                page: Number(dataLabeledMeters.paginationInfo.page),
                pageSize: Number(dataLabeledMeters.paginationInfo.pageSize),
              }
            : undefined
        }
        progress={dataLabeledMeters?.items ?? []}
        title={t('pages.organization.billing.invoicedMeter')}
        totalItems={dataLabeledMeters?.paginationInfo?.totalItems}
      />
      {isRentedScannerEnabled && (
        <ScannerTable
          isDownloading={downloadIdScanner !== null && !downloadScannerError}
          isLoading={isLoadingScannerRent && !downloadScannerError}
          onDowload={downloadScanner}
          onPaginationModelChange={setPaginationModelScanner}
          paginationMode="server"
          paginationModel={
            dataScannerRent?.paginationInfo
              ? {
                  page: Number(dataScannerRent.paginationInfo.page),
                  pageSize: Number(dataScannerRent.paginationInfo.pageSize),
                }
              : undefined
          }
          progress={dataScannerRent?.items ?? []}
          title={t('pages.organization.billing.rentedScanner')}
          totalItems={dataScannerRent?.paginationInfo?.totalItems}
        />
      )}
    </Stack>
  );
};
