import type { billingApiV1 } from '@deepup/apis';
import { ArrowDownLine } from '@deepup/icons';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import {
  DataGridPro,
  type DataGridProProps,
  type GridColDef,
  type GridColumnGroupingModel,
  type GridFeatureMode,
  type GridPaginationModel,
} from '@mui/x-data-grid-pro';
import { useFlags } from 'flagsmith/react';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { defaultPaginationModel } from '../OrganizationsEditDashboard';

export const tableStyles: DataGridProProps['sx'] = (theme) => ({
  '& .cell-price': {
    backgroundColor: `${theme.deepupColors.gold[50]}`,
  },
  '.MuiDataGrid-row:hover > .cell-price': {
    backgroundColor: `${theme.deepupColors.gold[100]}`,
  },
  '& .cell-price-total': {
    backgroundColor: `${theme.deepupColors.gold[100]}`,
  },
  '.MuiDataGrid-row:hover > .cell-price-total': {
    backgroundColor: `${theme.deepupColors.gold[200]}`,
  },
});

export const TableToolbar = ({
  title,
  subtitle,
  onDowload,
  loading,
}: {
  title: string;
  onDowload?: () => void;
  subtitle?: string;
  loading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      alignItems={'center'}
      borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      direction="row"
      justifyContent="space-between"
      paddingY={2}
      spacing={2}
    >
      <Box>
        <Typography variant="h5">{title}</Typography>
        {subtitle && <Typography variant="body2">{subtitle}</Typography>}
      </Box>
      {!!onDowload && (
        <LoadingButton
          color="secondary"
          disabled={!onDowload}
          endIcon={<ArrowDownLine />}
          loading={loading}
          onClick={onDowload}
          variant="outlined"
        >
          {t('common.download')}
        </LoadingButton>
      )}
    </Stack>
  );
};

export const MeterTable = ({
  progress,
  paginationModel,
  onPaginationModelChange,
  totalItems,
  isLoading,
  paginationMode,
  onStateChange,
  title,
  onDowload,
  isDownloading,
  hasProjects,
}: {
  progress: billingApiV1.GetLabeledMetersResponse['items'];
  paginationModel?: GridPaginationModel | undefined;
  onPaginationModelChange?: (model: GridPaginationModel) => void;
  totalItems?: bigint;
  isLoading?: boolean;
  paginationMode: GridFeatureMode;
  onStateChange?: DataGridProProps['onStateChange'];
  title: string;
  onDowload?: () => void;
  isDownloading?: boolean;
  hasProjects: boolean;
}) => {
  const { t } = useTranslation();
  // Get the organization ID from the URL
  const { id } = useParams();

  const { billing_prices_enabled } = useFlags(['billing_prices_enabled']);

  // NOTE: This is a workaround to prevent the table from showing prices for certain organizations.
  // Since the product want to define this list on the fly, we have to use a feature flag.
  const excludedOrgIds =
    (billing_prices_enabled.value &&
      JSON.parse(billing_prices_enabled.value as string)?.excludedOrgIds) ??
    [];

  const hidePrices =
    (billing_prices_enabled.enabled && excludedOrgIds.includes(id)) ||
    !billing_prices_enabled.enabled;

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCount` from being undefined during the loading
  const rowCountRef = useRef(totalItems || BigInt(0));

  const rowCount = useMemo(() => {
    if (totalItems !== undefined) {
      rowCountRef.current = totalItems;
    }

    return Number(rowCountRef.current);
  }, [totalItems]);

  const rows = useMemo(() => {
    return progress.map((row) => ({
      id: row.project?.id ?? '',
      projectName: row.project?.name ?? '',
      openMeters: row.labeledMeters?.openMeters,
      pricePerMeterClosed: row.pricesOfLabeledMeters?.closedMeters,
      closedMeters: row.labeledMeters?.closedMeters,
      pricePerMeterOpen: row.pricesOfLabeledMeters?.openMeters,
      layjetMeters: row.labeledMeters?.layjetMeters,
      pricePerMeterLayjet: row.pricesOfLabeledMeters?.layjetMeters,
      otherMeters: row.labeledMeters?.otherMeters,
      pricePerMeterOther: row.pricesOfLabeledMeters?.otherMeters,
      totalMeters: row.labeledMeters?.totalMeters,
      pricePerMeterTotal: row.pricesOfLabeledMeters?.totalMeters,
    }));
  }, [progress]);

  const columns: GridColDef[] = [
    {
      field: 'projectName',
      headerName: t('pages.organization.billing.meterTable.projectName'),
      flex: 2,
    },
    {
      field: 'openMeters',
      headerName: t('pages.organization.billing.meterTable.openMeters'),
      valueFormatter: (val) => t('decimal', { val }),
      flex: 1,
    },
    {
      field: 'pricePerMeterOpen',
      headerName: t('pages.organization.billing.meterTable.price'),
      cellClassName: 'cell-price',
      headerClassName: 'cell-price',
      valueFormatter: (val) => `${t('decimal', { val })}€`,
      flex: 1,
    },
    {
      field: 'closedMeters',
      headerName: t('pages.organization.billing.meterTable.closedMeters'),
      valueFormatter: (val) => t('decimal', { val }),
      flex: 1,
    },
    {
      field: 'pricePerMeterClosed',
      headerName: t('pages.organization.billing.meterTable.price'),
      cellClassName: 'cell-price',
      headerClassName: 'cell-price',
      valueFormatter: (val) => `${t('decimal', { val })}€`,
      flex: 1,
    },
    {
      field: 'layjetMeters',
      headerName: t('pages.organization.billing.meterTable.layjetMeters'),
      valueFormatter: (val) => t('decimal', { val }),
      flex: 1,
    },
    {
      field: 'pricePerMeterLayjet',
      headerName: t('pages.organization.billing.meterTable.price'),
      cellClassName: 'cell-price',
      headerClassName: 'cell-price',
      valueFormatter: (val) => `${t('decimal', { val })}€`,
      flex: 1,
    },
    {
      field: 'otherMeters',
      headerName: t('pages.organization.billing.meterTable.otherMeters'),
      valueFormatter: (val) => t('decimal', { val: val }),
      flex: 1,
    },
    {
      field: 'pricePerMeterOther',
      headerName: t('pages.organization.billing.meterTable.price'),
      cellClassName: 'cell-price',
      headerClassName: 'cell-price',
      valueFormatter: (val) => `${t('decimal', { val })}€`,
      flex: 1,
    },
    {
      field: 'totalMeters',
      headerName: t('pages.organization.billing.meterTable.meters'),
      valueFormatter: (val) => t('decimal', { val }),
      flex: 1,
    },
    {
      field: 'pricePerMeterTotal',
      headerName: t('pages.organization.billing.meterTable.price'),
      cellClassName: 'cell-price-total',
      headerClassName: 'cell-price-total',
      valueFormatter: (val) => `${t('decimal', { val })}€`,
      flex: 1,
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: t('pages.organization.billing.meterTable.overview'),
      children: [{ field: 'projectName' }],
    },
    {
      groupId: t('pages.organization.billing.meterTable.metersByLayingType'),
      children: [
        { field: 'openMeters' },
        { field: 'closedMeters' },
        { field: 'layjetMeters' },
        { field: 'otherMeters' },
        { field: 'pricePerMeterOpen' },
        { field: 'pricePerMeterClosed' },
        { field: 'pricePerMeterLayjet' },
        { field: 'pricePerMeterOther' },
      ],
    },
    {
      groupId: t('pages.organization.billing.meterTable.totalMeters'),
      children: [{ field: 'totalMeters' }, { field: 'pricePerMeterTotal' }],
    },
  ];

  return (
    <DataGridPro
      columnGroupingModel={columnGroupingModel}
      columnVisibilityModel={{
        pricePerMeterOpen: !hidePrices,
        pricePerMeterClosed: !hidePrices,
        pricePerMeterLayjet: !hidePrices,
        pricePerMeterOther: !hidePrices,
        pricePerMeterTotal: !hidePrices,
      }}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableRowSelectionOnClick
      initialState={{
        pagination: {
          paginationModel: defaultPaginationModel,
          rowCount,
        },
      }}
      loading={isLoading}
      onPaginationModelChange={onPaginationModelChange}
      onStateChange={onStateChange}
      pageSizeOptions={rowCount && rowCount > 5 ? [5, 10, 20, 50, 100] : [5]}
      pagination
      paginationMode={paginationMode}
      paginationModel={paginationModel}
      rowCount={paginationMode === 'server' ? rowCount : undefined}
      rows={rows}
      showCellVerticalBorder
      showColumnVerticalBorder
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
      slots={{
        toolbar: () => (
          <TableToolbar
            loading={isDownloading}
            onDowload={hidePrices || !hasProjects ? undefined : () => onDowload?.()}
            subtitle={t('pages.organization.billing.meterTable.header.title', { count: rowCount })}
            title={title}
          />
        ),
        noRowsOverlay: () => (
          <Typography align="center" paddingTop={4}>
            {hasProjects
              ? t('pages.organization.billing.noData')
              : t('pages.organization.billing.noProjects')}
          </Typography>
        ),
        noResultsOverlay: () => (
          <Typography align="center" paddingTop={4}>
            {t('pages.organization.billing.noData')}
          </Typography>
        ),
      }}
      sx={tableStyles}
    />
  );
};
