import { useCallback } from 'react';

import { useJsonFetch } from '@hooks/api/useJsonFetch';
import { buildBody } from '@hooks/api/utils';
import type { Organization } from '@models/organizations';

export const useOrganizationUpdateApi = () => {
  const jsonFetch = useJsonFetch<Organization>();

  const fetcher = useCallback(
    ({
      id,
      name,
      abbreviation,
      dashboardSource,
      invoiceToolEnabled,
      dbbAssetReviewEnabled,
      version,
      priceModel,
      billingAddress,
      labeledMeterInvoiceFormat,
    }: Partial<Organization>) =>
      jsonFetch(`/organizations/${id}`, {
        method: 'PATCH',

        body: buildBody({
          name,
          abbreviation,
          dashboardSource,
          invoiceToolEnabled,
          dbbAssetReviewEnabled,
          version,
          priceModel,
          billingAddress,
          labeledMeterInvoiceFormat,
        }),
      }),
    [jsonFetch],
  );

  return fetcher;
};
