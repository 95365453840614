import type { GridSortModel } from '@mui/x-data-grid-pro';

import { useApiWithOptions } from '../useApiWithOptions';
import { convertSortModelToString } from '../utils/convertSortModelToString';

import type { Group } from './types';

interface GroupQueryOptions {
  page: number;
  size: number;
  sortModel?: GridSortModel;
  name?: string;
  isDeepupOrg?: boolean;
  organizationId?: string;
}

const constructQueryStringForGroup = ({
  page,
  size,
  sortModel,
  name,
  organizationId,
  isDeepupOrg = false,
}: GroupQueryOptions): string => {
  const sortParam = convertSortModelToString(sortModel);
  const queryParams = {
    page: page.toString(),
    size: size.toString(),
    ...(name && { name }),
    ...(organizationId && { organizationId }),
    ...(sortParam && { sort: sortParam }),
    isDeepupOrg: isDeepupOrg.toString(),
  };

  const searchParams = new URLSearchParams(queryParams);

  return `?${searchParams.toString()}`;
};

export function useGroupsPaginatedApi(queryOptions: GroupQueryOptions) {
  return useApiWithOptions<Group>({
    context: 'groups',
    queryString: constructQueryStringForGroup(queryOptions),
  });
}
