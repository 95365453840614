import { CircleQuestionmarkOutline, XOutline } from '@deepup/icons';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { DataGridPro, type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useFlags } from 'flagsmith/react';
import { useEffect, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { renderCellContent } from '@components/TableCell/TableCell';
import { useOrganizationsApi } from '@hooks/api/organizations';
import type { ProjectGroupBackofficeRole, ProjectPermission } from '@hooks/api/projects';
import { usePlatformPermissions } from '@hooks/usePlatformPermissions';

import { useFormGroupState } from '../../useFormGroupState';

import { BackofficeRoleSelect } from './BackofficeRoleSelect';

export type PermissionTypes =
  | 'hasMonitoring'
  | 'hasDocumentation'
  | 'hasInvoiceTool'
  | 'backofficeRole';

interface ProjectsInGroupTableProps {
  entities: ProjectPermission[];
  onChangeProjectPermission: (
    entity: ProjectPermission,
    permission: PermissionTypes,
    value: boolean | ProjectGroupBackofficeRole,
  ) => void;
  onDeleteProject: (projectId: string) => void;
  onDeleteAllProjects: () => void;
  onClickHeaderSelectAll: (column: PermissionTypes[], isChecked: boolean) => void;
  isLoading: boolean;
  isDeepUpInternal: boolean;
}

export const ProjectsInGroupTable: FC<ProjectsInGroupTableProps> = ({
  entities,
  onChangeProjectPermission,
  onDeleteAllProjects,
  onDeleteProject,
  onClickHeaderSelectAll,
  isLoading,
  isDeepUpInternal,
}) => {
  const { t } = useTranslation();
  const featureFlags = useFlags(['invoice_tool_permissions_enabled']);
  const theme = useTheme();
  const iconSize = theme.spacing(2.5);
  const iconColor = theme.palette.secondary;

  const { isOrganizationAdmin, isPlatformAdmin } = usePlatformPermissions();
  const { data: organizationsData } = useOrganizationsApi();
  const { group } = useFormGroupState();

  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [selectAllDocumentationChecked, setSelectAllDocumentationChecked] =
    useState<boolean>(false);
  const [selectAllMonitoringChecked, setSelectAllMonitoringChecked] = useState<boolean>(false);
  const [selectAllInvoiceToolChecked, setSelectAllInvoiceToolChecked] = useState<boolean>(false);

  const canEdit = isPlatformAdmin || isOrganizationAdmin;

  const groupOrganization = organizationsData?.content.find(
    ({ id }) => id === group?.organizationId,
  );

  const invoiceToolEnabled =
    groupOrganization?.invoiceToolEnabled && featureFlags.invoice_tool_permissions_enabled.enabled;

  const handleSelectAll =
    (field: PermissionTypes) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onClickHeaderSelectAll([field], event.target.checked);
    };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('entities.projects.fields.name.label'),
      flex: 1,
      headerClassName: 'name-header',
      renderHeader: () => (
        <Typography color="secondary" variant="subtitle2">
          {t('entities.projects.fields.name.label')}
        </Typography>
      ),
      renderCell: (params) => {
        if (params.id === -1) {
          return (
            <Stack alignItems="center" flexDirection="row">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAllChecked}
                    onChange={(event, checked) => {
                      onClickHeaderSelectAll(
                        invoiceToolEnabled
                          ? ['hasDocumentation', 'hasMonitoring', 'hasInvoiceTool']
                          : ['hasDocumentation', 'hasMonitoring'],
                        checked,
                      );
                      setSelectAllChecked(checked);
                      setSelectAllDocumentationChecked(checked);
                      setSelectAllMonitoringChecked(checked);
                      if (invoiceToolEnabled) setSelectAllInvoiceToolChecked(checked);
                    }}
                  />
                }
                label={
                  <Typography color="secondary" variant="subtitle2">
                    {t('common.selectAll')}
                  </Typography>
                }
              />
            </Stack>
          );
        }

        return renderCellContent({
          entityType: 'projects',
          params,
          isLoading,
          columnField: 'name',
        });
      },
    },
    ...(isDeepUpInternal
      ? [
          {
            field: 'backofficeRole',
            headerName: t('pages.groupsPermissions.create.components.header.backoffice'),
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
              <Box width="100%">
                <BackofficeRoleSelect
                  onChange={(backofficeRole) =>
                    onChangeProjectPermission(params.row, 'backofficeRole', backofficeRole)
                  }
                  selectedRole={params.row.backofficeRole}
                />
              </Box>
            ),
          },
        ]
      : []),
    {
      field: 'hasMonitoring',
      headerName: t('pages.groupsPermissions.create.components.header.monitoring'),
      flex: 1,
      headerClassName: 'monitoring-header',
      renderHeader: () => (
        <Box alignItems="center" display="flex" gap={1}>
          <Typography color="secondary" variant="subtitle2">
            {t('pages.groupsPermissions.create.components.header.monitoring')}
          </Typography>
          <Tooltip
            arrow
            enterTouchDelay={0}
            placement="top"
            title={
              <>
                {t('pages.groupsPermissions.create.components.header.tooltipMonitoring')}{' '}
                <Link
                  href="https://cockpit.deepup.io"
                  style={{ color: 'inherit', textDecoration: 'underline' }}
                  target="_blank"
                >
                  cockpit.deepup.io
                </Link>
              </>
            }
          >
            <Stack alignItems="center">
              <CircleQuestionmarkOutline
                color={`${iconColor}`}
                height={iconSize}
                width={iconSize}
              />
            </Stack>
          </Tooltip>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams<ProjectPermission>) => {
        if (params.id === -1) {
          return (
            <Stack alignItems="center" flexDirection="row" marginLeft={-1}>
              <Checkbox
                checked={selectAllMonitoringChecked}
                onChange={handleSelectAll('hasMonitoring')}
              />
            </Stack>
          );
        }

        return (
          <Box alignItems="center" display="flex" marginLeft={-1} width="100%">
            <Checkbox
              checked={params.row.hasMonitoring}
              disabled={!canEdit}
              onChange={(e) =>
                onChangeProjectPermission(params.row, 'hasMonitoring', e.target.checked)
              }
            />
          </Box>
        );
      },
      sortable: false,
    },
    {
      field: 'hasDocumentation',
      headerName: t('pages.groupsPermissions.create.components.header.documentation'),
      flex: 1,
      renderHeader: () => (
        <Box alignItems="center" display="flex" gap={1} width="100%">
          <Typography color="secondary" variant="subtitle2">
            {t('pages.groupsPermissions.create.components.header.documentation')}
          </Typography>
          <Tooltip
            arrow
            enterTouchDelay={0}
            placement="top"
            title={
              <>
                {t('pages.groupsPermissions.create.components.header.tooltipDocumentation')}{' '}
                <Link
                  href="https://deepup.io"
                  style={{ color: 'inherit', textDecoration: 'underline' }}
                  target="_blank"
                >
                  deepup.io
                </Link>
              </>
            }
          >
            <Stack alignItems="center">
              <CircleQuestionmarkOutline
                color={`${iconColor}`}
                height={iconSize}
                width={iconSize}
              />
            </Stack>
          </Tooltip>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams<ProjectPermission>) => {
        if (params.id === -1) {
          const selectAllDocumentationCheckbox = (
            <Checkbox
              checked={selectAllDocumentationChecked}
              disabled={!selectAllMonitoringChecked}
              onChange={handleSelectAll('hasDocumentation')}
            />
          );

          return (
            <Box alignItems="center" display="flex" marginLeft={-1} width="100%">
              {selectAllMonitoringChecked ? (
                selectAllDocumentationCheckbox
              ) : (
                <Tooltip
                  title={t(
                    'pages.groupsPermissions.create.components.header.tooltipDocumentationDisabled',
                  )}
                >
                  <Box style={{ cursor: 'not-allowed' }}>{selectAllDocumentationCheckbox}</Box>
                </Tooltip>
              )}
            </Box>
          );
        }

        const projectDocumentationCheckbox = (
          <Checkbox
            checked={params.row.hasDocumentation}
            disabled={!params.row.hasMonitoring}
            onChange={(e) =>
              onChangeProjectPermission(params.row, 'hasDocumentation', e.target.checked)
            }
          />
        );

        return (
          <Box alignItems="center" display="flex" marginLeft={-1} width="100%">
            {params.row.hasMonitoring ? (
              projectDocumentationCheckbox
            ) : (
              <Tooltip
                title={t(
                  'pages.groupsPermissions.create.components.header.tooltipDocumentationDisabled',
                )}
              >
                <Box style={{ cursor: 'not-allowed' }}>{projectDocumentationCheckbox}</Box>
              </Tooltip>
            )}
          </Box>
        );
      },
      sortable: false,
    },
    ...(invoiceToolEnabled
      ? [
          {
            field: 'hasInvoiceTool',
            headerName: t('pages.groupsPermissions.create.components.header.invoiceTool'),
            flex: 1,
            renderHeader: () => (
              <Box alignItems="center" display="flex" gap={1} width="100%">
                <Typography color="secondary" variant="subtitle2">
                  {t('pages.groupsPermissions.create.components.header.invoiceTool')}
                </Typography>
                <Tooltip
                  arrow
                  enterTouchDelay={0}
                  placement="top"
                  title={
                    <>
                      {t('pages.groupsPermissions.create.components.header.tooltipInvoiceTool')}{' '}
                      <Link
                        href="https://invoice-tool.deepup.io"
                        style={{ color: 'inherit', textDecoration: 'underline' }}
                        target="_blank"
                      >
                        Invoice Tool
                      </Link>
                    </>
                  }
                >
                  <Stack alignItems="center">
                    <CircleQuestionmarkOutline
                      color={`${iconColor}`}
                      height={iconSize}
                      width={iconSize}
                    />
                  </Stack>
                </Tooltip>
              </Box>
            ),
            renderCell: (params: GridRenderCellParams<ProjectPermission>) => {
              if (params.id === -1) {
                return (
                  <Box marginLeft={-1} width="100%">
                    <Checkbox
                      checked={selectAllInvoiceToolChecked}
                      onChange={handleSelectAll('hasInvoiceTool')}
                    />
                  </Box>
                );
              }

              return (
                <Box marginLeft={-1} width="100%">
                  <Checkbox
                    checked={params.row.hasInvoiceTool}
                    onChange={(e) =>
                      onChangeProjectPermission(params.row, 'hasInvoiceTool', e.target.checked)
                    }
                  />
                </Box>
              );
            },
            sortable: false,
          },
        ]
      : []),
    {
      field: 'context-menu',
      headerName: '',
      flex: 1,
      headerClassName: 'context-menu-header',
      renderCell: (params: GridRenderCellParams<ProjectPermission>) => {
        if (params.id === -1) {
          return (
            <Box display="flex" justifyContent="flex-end" width="100vw">
              {entities.length > 0 && (
                <Button
                  color="secondary"
                  disabled={!canEdit}
                  onClick={() => onDeleteAllProjects()}
                  startIcon={<XOutline />}
                  variant="text"
                >
                  {t('pages.groupsPermissions.create.components.btnRemoveAllProjects')}
                </Button>
              )}
            </Box>
          );
        }

        return (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button
              color="secondary"
              onClick={() => onDeleteProject(params.row.projectId)}
              startIcon={<XOutline />}
              variant="text"
            >
              {t('pages.groupsPermissions.create.components.btnRemoveProject')}
            </Button>
          </Box>
        );
      },
      sortable: false,
    },
  ];

  const rows = [
    {
      id: -1,
      projectId: undefined,
      name: undefined,
      backofficeRole: undefined,
      hasDocumentation: undefined,
      hasMonitoring: undefined,
      'context-menu': null,
    },
    ...entities.map((entity, index) => ({
      id: index,
      projectId: entity.projectId,
      name: entity.project.name,
      backofficeRole: entity.backofficeRole,
      hasDocumentation: entity.hasDocumentation,
      hasMonitoring: entity.hasMonitoring,
      hasInvoiceTool: entity.hasInvoiceTool,
      'context-menu': null,
    })),
  ];

  useEffect(() => {
    const allDocumentationChecked =
      !!entities.length && entities.every((entity) => entity.hasDocumentation);
    const allMonitoringChecked =
      !!entities.length && entities.every((entity) => entity.hasMonitoring);
    const allInvoiceToolChecked =
      !!entities.length && entities.every((entity) => entity.hasInvoiceTool);

    const allChecked = invoiceToolEnabled
      ? allDocumentationChecked && allMonitoringChecked && allInvoiceToolChecked
      : allDocumentationChecked && allMonitoringChecked;

    setSelectAllDocumentationChecked(allDocumentationChecked);
    setSelectAllMonitoringChecked(allMonitoringChecked);
    setSelectAllInvoiceToolChecked(allInvoiceToolChecked);
    setSelectAllChecked(allChecked);
  }, [entities, invoiceToolEnabled]);

  return (
    <Box width="100%">
      <DataGridPro
        autoHeight
        columnHeaderHeight={Number.parseInt(theme.spacing(3))}
        columns={columns}
        data-testid="table-groups-allProjects"
        disableColumnMenu
        disableRowSelectionOnClick
        loading={isLoading}
        pagination
        rows={rows}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        sortingOrder={['asc', 'desc']}
        sx={{
          '.MuiDataGrid-columnHeaders': {
            borderColor: 'transparent',
          },
          '[data-id="-1"]': {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        }}
      />
    </Box>
  );
};
