import { RpcRestError } from '@deepup/api-utils';
import {
  commonApiV1,
  billingApiV1 as billingApi,
  billingClientApiV1 as billingClientApi,
} from '@deepup/apis';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { useGrpcRestTransport } from '@hooks/api/useGrpcTransport';

import { useSWRMutationErrorHandler } from './useErrorHandler';

export const useApiGetInvoicedMeters = ({
  organizationId,
  projectIds,
  listOptions,
  dateRange,
}: {
  organizationId: string | null;
  projectIds?: string[];
  dateRange: {
    start: string;
    end: string;
  };
  listOptions: {
    pageSize: number;
    page: number;
  };
}) => {
  const transport = useGrpcRestTransport();
  const handleError = useSWRMutationErrorHandler();

  const key = [
    'billing',
    'getLabeledMeters',
    organizationId,
    projectIds,
    listOptions.pageSize,
    listOptions.page,
    dateRange.start,
    dateRange.end,
  ].join('-');

  const isEnabled = !!organizationId && projectIds && projectIds?.length > 0;

  const timeRange = commonApiV1.TimeRange.fromJson({
    start: dateRange.start,
    end: dateRange.end,
  });

  return useSWR<billingApi.GetLabeledMetersResponse, RpcRestError>(
    key,
    !isEnabled
      ? null
      : () => {
          const client = new billingClientApi.BillingServiceClient(transport);

          return client.getLabeledMeters({
            organizationId: organizationId ?? '',
            timeRange,
            projectIds: projectIds ?? [],
            options: {
              pageSize: listOptions.pageSize,
              page: BigInt(listOptions.page),
              contextId: '',
              withPaginationInfo: true,
            },
          }).response;
        },
    { onError: handleError },
  );
};

export const useApiDownloadInvoicedMeters = ({
  organizationId,
  listOptions,
  dateRange,
  projectIds,
  id,
}: {
  organizationId: string | null;
  id: string | null;
  projectIds?: string[];
  dateRange: {
    start: string;
    end: string;
  };
  listOptions: {
    pageSize: number;
    page: number;
  };
}) => {
  const transport = useGrpcRestTransport();
  const handleError = useSWRMutationErrorHandler();

  const timeRange = commonApiV1.TimeRange.fromJson({
    start: dateRange.start,
    end: dateRange.end,
  });

  const key = [
    'billing',
    'getLabeledMeters',
    'download',
    organizationId,
    id,
    listOptions.pageSize,
    listOptions.page,
    dateRange.start,
    dateRange.end,
  ].join('-');

  return useSWRMutation<billingApi.DownloadResponse, RpcRestError>(
    !projectIds || !organizationId ? null : key,
    () => {
      const client = new billingClientApi.BillingServiceClient(transport);

      return client.downloadLabeledMeters({
        organizationId: organizationId ?? '',
        projectIds: projectIds ?? [],
        timeRange,
        options: {
          pageSize: listOptions.pageSize,
          page: BigInt(listOptions.page),
          contextId: '',
          withPaginationInfo: true,
        },
      }).response;
    },
    { onError: handleError },
  );
};

export const useApiDownloadScanner = ({
  organizationId,
  listOptions,
  dateRange,
  id,
}: {
  organizationId: string | null;
  id: string | null;
  dateRange: {
    start: string;
    end: string;
  };
  listOptions: {
    pageSize: number;
    page: number;
  };
}) => {
  const transport = useGrpcRestTransport();
  const handleError = useSWRMutationErrorHandler();

  const timeRange = commonApiV1.TimeRange.fromJson({
    start: dateRange.start,
    end: dateRange.end,
  });

  const key = [
    'billing',
    'getScannerRent',
    'download',
    organizationId,
    id,
    listOptions.pageSize,
    listOptions.page,
    dateRange.start,
    dateRange.end,
  ].join('-');

  return useSWRMutation<billingApi.DownloadResponse, RpcRestError>(
    key,
    () => {
      const client = new billingClientApi.BillingServiceClient(transport);

      return client.downloadScannerRent({
        organizationId: organizationId ?? '',
        timeRange,
        options: {
          pageSize: listOptions.pageSize,
          page: BigInt(listOptions.page),
          contextId: '',
          withPaginationInfo: true,
        },
      }).response;
    },
    { onError: handleError },
  );
};

export const useApiDownloadStatus = ({ id }: { id: string }) => {
  const transport = useGrpcRestTransport();
  const handleError = useSWRMutationErrorHandler();

  const key = ['billing', 'download', 'status'].join('-');

  return useSWRMutation<billingApi.DownloadStatus, RpcRestError>(
    key,
    () => {
      const client = new billingClientApi.BillingServiceClient(transport);

      return client.getDownloadStatus({ id }).response;
    },
    { onError: handleError },
  );
};
