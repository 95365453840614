import { Plus } from '@deepup/icons';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageHeader } from '@components/PageHeader';

export interface OverviewPageLayoutProps {
  title: string;
  createRoute?: string;
  createTitle?: string;
  children: ReactNode;
  secondaryAction?: {
    label: string;
    onClick: () => void;
  };
}

export const OverviewPageLayout: FC<OverviewPageLayoutProps> = ({
  title,
  createRoute,
  createTitle,
  children,
  secondaryAction,
}) => {
  const navigate = useNavigate();

  const handleCreate = () => {
    if (createRoute) navigate(createRoute);
  };

  return (
    <Box flexDirection="column" flexGrow={1}>
      <Box component="header" display="flex" justifyContent="space-between" mb={2}>
        <PageHeader>{title}</PageHeader>
        <Box display="flex" gap={4}>
          {!!secondaryAction && (
            <Button
              color="secondary"
              data-testid={'secondary-action'}
              onClick={secondaryAction.onClick}
              variant="outlined"
            >
              {secondaryAction.label}
            </Button>
          )}
          {createRoute && createRoute.length !== 0 && (
            <Button
              color="primary"
              data-testid={'create-button'}
              onClick={handleCreate}
              startIcon={<Plus fill="currentColor" />}
              variant="contained"
            >
              {createTitle}
            </Button>
          )}
        </Box>
      </Box>
      <Box my={4} />
      {children}
    </Box>
  );
};
